body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #efefef !important; }

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
  z-index: -10;
  background: url("https://res.cloudinary.com/momants/image/upload/v1596464471/BG-2_qunjue.jpg") no-repeat center center/cover; }

* {
  font-family: "Cairo", sans-serif; }

a[disabled] {
  background: #ccc; }

p {
  text-align: start; }

.container {
  max-width: 1140px !important;
  padding: 1rem; }

.content {
  -webkit-margin-start: 200px;
          margin-inline-start: 200px;
  padding: 4rem 1.5rem; }

.main-app {
  overflow-x: hidden; }

.showcase, .showcase-admin {
  width: 100vw;
  height: 100vh;
  background: url("https://res.cloudinary.com/momants/image/upload/v1588176274/showcase_r2bu3c.jpg") no-repeat center center/cover; }
  .showcase .faded, .showcase-admin .faded {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); }

.showcase-admin {
  background: url("https://res.cloudinary.com/momants/image/upload/v1588176181/admin_k0nohj.jpg") no-repeat center center/cover; }

.lang {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 3; }
  .lang i {
    display: inline-block;
    color: #ef7a30;
    margin: 0;
    padding-left: 6px; }
  .lang button {
    background: none;
    border: none;
    color: black;
    outline: none;
    padding: 0;
    padding-left: 6px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .lang button:hover {
      color: #c53736; }

.lang-top {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 3; }
  .lang-top i {
    display: inline-block;
    color: #ef7a30;
    margin: 0;
    padding-left: 6px; }
  .lang-top button {
    background: none;
    border: none;
    color: black;
    outline: none;
    padding: 0;
    padding-left: 6px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .lang-top button:hover {
      color: #c53736; }

.lang-normal i {
  display: inline-block;
  color: #ef7a30;
  margin: 0;
  padding-left: 6px; }

.lang-normal button {
  background: none;
  border: none;
  color: black;
  outline: none;
  padding: 0;
  padding-left: 6px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .lang-normal button:hover {
    color: #c53736; }

/* width */
::-webkit-scrollbar {
  width: 13px; }

/* Track */
::-webkit-scrollbar-track {
  background: #bbb; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #de7e4c; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ef7a30; }

.double-grid {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  justify-content: center;
  align-items: center; }

.single-grid {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.triple-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem; }

.inline-ant {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  justify-content: center;
  align-items: center;
  width: 100%; }

.paginate {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem auto; }
  .paginate .ant-pagination .ant-pagination-item-active {
    background: -webkit-gradient(linear, left top, right top, from(#c53736), to(#ef7a30));
    background: linear-gradient(to right, #c53736, #ef7a30); }
    .paginate .ant-pagination .ant-pagination-item-active a {
      color: white; }

.ant-empty {
  padding: 1rem; }
  .ant-empty .ant-empty-description {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    font-size: 1.5rem;
    color: #c53736; }

.link-desc {
  margin-bottom: 0;
  font-size: 0.8rem; }

.link {
  color: #de7e4c;
  font-size: 0.8rem; }
  .link:hover {
    text-decoration: none;
    color: #f4623a; }

.btn-ok {
  padding: 10px 30px;
  font-size: 1.3rem;
  background: -webkit-gradient(linear, left top, right top, from(#c53736), to(#ef7a30));
  background: linear-gradient(to right, #c53736, #ef7a30);
  color: white;
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 20px 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .btn-ok:focus {
    outline: none; }
  .btn-ok i {
    color: white;
    padding: 0 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .btn-ok:hover {
    background: -webkit-gradient(linear, left top, right top, from(#c53736), color-stop(#ef7a30), to(#ef7a30));
    background: linear-gradient(to right, #c53736, #ef7a30, #ef7a30);
    text-decoration: none;
    color: black;
    border-radius: 0; }
    .btn-ok:hover i {
      color: black; }

.btn-del {
  padding: 10px 30px;
  font-size: 1.3rem;
  background: #ccc;
  color: #c53736;
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 20px 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer; }
  .btn-del:focus {
    outline: none; }
  .btn-del i {
    color: #c53736;
    padding: 0 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .btn-del:hover {
    background: #777;
    text-decoration: none;
    color: black;
    border-radius: 0; }
    .btn-del:hover i {
      color: black; }

.btn-mess {
  padding: 10px 30px;
  font-size: 1.3rem;
  background: -webkit-gradient(linear, left top, right top, from(#0088d2), to(#23aaf2));
  background: linear-gradient(to right, #0088d2, #23aaf2);
  color: white;
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 20px 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer; }
  .btn-mess:focus {
    outline: none; }
  .btn-mess i {
    color: white;
    padding: 0 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .btn-mess:hover {
    background: -webkit-gradient(linear, left top, right top, from(#23aaf2), to(#0088d2));
    background: linear-gradient(to right, #23aaf2, #0088d2);
    text-decoration: none;
    color: black;
    border-radius: 0; }
    .btn-mess:hover i {
      color: black; }

.btn-white {
  padding: 10px 30px;
  font-size: 1.3rem;
  background: white;
  color: black;
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 20px 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer; }
  .btn-white:focus {
    outline: none; }
  .btn-white i {
    color: black;
    padding: 0 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .btn-white:hover {
    background: #eee;
    text-decoration: none;
    color: black;
    border-radius: 0; }
    .btn-white:hover i {
      color: black; }

.btn-gr {
  padding: 10px 30px;
  font-size: 1.3rem;
  background: #6ac045;
  color: black;
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 20px 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer; }
  .btn-gr:focus {
    outline: none; }
  .btn-gr i {
    color: black;
    padding: 0 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .btn-gr:hover {
    background: #39a914;
    text-decoration: none;
    color: black;
    border-radius: 0; }
    .btn-gr:hover i {
      color: black; }

.btn-menu {
  padding: 10px 30px;
  font-size: 1.3rem;
  background: white;
  color: #444;
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 20px 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin: 0;
  padding: 10px 20px 10px 20px;
  width: 100% !important;
  font-size: 1.1rem;
  border-radius: 0;
  text-align: start !important; }
  .btn-menu:focus {
    outline: none; }
  .btn-menu i {
    color: #444;
    padding: 0 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .btn-menu:hover {
    background: #eee;
    text-decoration: none;
    color: black;
    border-radius: 0; }
    .btn-menu:hover i {
      color: black; }
  .btn-menu i {
    padding: 0 10px; }
  .btn-menu:hover i {
    padding: 0 12px; }

.btn-drop {
  color: black;
  text-align: start; }
  .btn-drop:hover {
    text-decoration: none;
    color: #ef7a30; }
  .btn-drop i {
    padding: 0 8px; }

.btn-bar {
  padding: 10px 30px;
  font-size: 1.3rem;
  background: white;
  color: #444;
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 20px 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin: 0 !important;
  border-radius: 0 !important; }
  .btn-bar:focus {
    outline: none; }
  .btn-bar i {
    color: #444;
    padding: 0 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .btn-bar:hover {
    background: -webkit-gradient(linear, left top, right top, from(#c53736), to(#ef7a30));
    background: linear-gradient(to right, #c53736, #ef7a30);
    text-decoration: none;
    color: black;
    border-radius: 0; }
    .btn-bar:hover i {
      color: black; }
  .btn-bar:hover {
    color: white; }

.head-white {
  color: white;
  font-size: 1.5rem;
  margin: 1rem 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.head-main {
  color: #ef7a30;
  font-size: 1.5rem;
  margin: 1rem 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.head-line {
  font-size: 2rem;
  margin-bottom: 0;
  color: #de7e4c; }

.line {
  width: 80%;
  height: 2.5px;
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#ef7a30), to(transparent));
  background: linear-gradient(to right, transparent, #ef7a30, transparent);
  margin: 25px auto; }

.line-f {
  width: 80%;
  height: 2.5px;
  background: -webkit-gradient(linear, left top, right top, from(#ef7a30), to(transparent));
  background: linear-gradient(to right, #ef7a30, transparent);
  margin: 25px auto; }

.parag-center-white {
  color: #ccc;
  max-width: 300px;
  font-size: 1rem;
  text-align: center; }

.info-parag {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem 0.5rem;
  margin-top: 0;
  color: black;
  font-size: 1.1rem; }

.home-page {
  overflow-x: hidden; }

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: fixed;
  border-radius: 0 0 35px 35px;
  box-shadow: 0px 0px 15px -7px #333;
  width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  padding: 20px 0;
  padding-bottom: 0;
  top: 0; }

.active {
  color: #ef7a30;
  background: #eee; }
  .active i {
    color: #ef7a30; }

.search-modal {
  background: #eee !important;
  margin: 0 0.5rem !important;
  border-radius: 10px;
  margin-bottom: 1rem !important; }
  .search-modal li a {
    color: black; }
    .search-modal li a:hover {
      color: #ef7a30; }

.global-dis {
  position: absolute;
  top: 50px;
  width: 100vw;
  height: 100vh; }

.search-global {
  background: white !important;
  position: absolute;
  top: 0;
  box-shadow: 0px 0px 25px -9px #333 !important;
  border-radius: 10px; }
  .search-global .ant-menu-item-group .ant-menu-item-group-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr); }

.ant-menu-item-group-title {
  padding-bottom: 0 !important;
  text-align: start; }

.bold {
  font-weight: bold; }

.error {
  font-size: 0.9rem;
  margin-bottom: 0 !important;
  padding: 0.5rem !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.counter-card {
  background: white;
  display: inline-block;
  margin: auto;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 15px -7px #ef7a30;
  width: 250px;
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .counter-card i {
    font-size: 3rem;
    align-self: center;
    color: #ef7a30; }
  .counter-card .info {
    align-self: center;
    text-align: right; }
    .counter-card .info p {
      margin: 0;
      color: black; }
    .counter-card .info .count {
      font-size: 2rem;
      color: #ef7a30; }
  .counter-card:hover {
    background: #ef7a30; }
    .counter-card:hover i {
      color: white; }
    .counter-card:hover .info p {
      color: white; }

.alert-card {
  background: white;
  display: inline-block;
  margin: auto;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 15px -7px #c53736;
  width: 250px;
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .alert-card i {
    font-size: 3rem;
    align-self: center;
    color: #c53736; }
  .alert-card .info {
    align-self: center;
    text-align: right; }
    .alert-card .info p {
      margin: 0;
      color: black; }
    .alert-card .info .count {
      font-size: 2rem;
      color: #c53736; }
  .alert-card:hover {
    background: #c53736; }
    .alert-card:hover i {
      color: white; }
    .alert-card:hover .info p {
      color: white; }

.alert {
  padding: 1rem;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#ef7a30), to(indianred)) !important;
  background: linear-gradient(to right, #ef7a30, indianred) !important;
  border-radius: 5px;
  color: white !important;
  border: none !important;
  box-shadow: 0px 0px 15px -5px black; }

.gradient-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.9)));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.9));
  padding: 20px;
  border-radius: 10px; }

.ant-badge-dot {
  top: 2px !important;
  right: 10px !important; }

@-webkit-keyframes ldio-ybix7bsblui {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes ldio-ybix7bsblui {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.ldio-ybix7bsblui div {
  position: absolute;
  -webkit-animation: ldio-ybix7bsblui 1s linear infinite;
          animation: ldio-ybix7bsblui 1s linear infinite;
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #ef7a30;
  -webkit-transform-origin: 50px 52px;
          transform-origin: 50px 52px; }

.loadingio-spinner-eclipse-25x4osfi4yb {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent; }

.ldio-ybix7bsblui {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  /* see note above */ }

.ldio-ybix7bsblui div {
  box-sizing: content-box; }

.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 4; }

.tiny-loader {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px; }

/* generated by https://loading.io/ */
@-webkit-keyframes ldio-ysmpid058t {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes ldio-ysmpid058t {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.ldio-ysmpid058t div {
  box-sizing: border-box !important; }

.ldio-ysmpid058t > div {
  position: absolute;
  width: 82px;
  height: 82px;
  top: 9px;
  left: 9px;
  border-radius: 50%;
  border: 4px solid #000;
  border-color: #ffffff transparent #ffffff transparent;
  -webkit-animation: ldio-ysmpid058t 1s linear infinite;
          animation: ldio-ysmpid058t 1s linear infinite; }

.ldio-ysmpid058t > div:nth-child(2) {
  border-color: transparent; }

.ldio-ysmpid058t > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.ldio-ysmpid058t > div:nth-child(2) div:before,
.ldio-ysmpid058t > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  top: -4px;
  left: 35px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 78px 0 0 #ffffff; }

.ldio-ysmpid058t > div:nth-child(2) div:after {
  left: -4px;
  top: 35px;
  box-shadow: 78px 0 0 0 #ffffff; }

.loadingio-spinner-dual-ring-c8aqmf23tgd {
  width: 37px;
  height: 37px;
  display: inline-block;
  overflow: hidden;
  background: none; }

.ldio-ysmpid058t {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(0.37);
          transform: translateZ(0) scale(0.37);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  /* see note above */ }

.ldio-ysmpid058t div {
  box-sizing: content-box; }

/* generated by https://loading.io/ */
@-webkit-keyframes ldio-m58iv7a50dp {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes ldio-m58iv7a50dp {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.ldio-m58iv7a50dp div {
  box-sizing: border-box !important; }

.ldio-m58iv7a50dp > div {
  position: absolute;
  width: 82px;
  height: 82px;
  top: 9px;
  left: 9px;
  border-radius: 50%;
  border: 4px solid #000;
  border-color: #ef7a30 transparent #ef7a30 transparent;
  -webkit-animation: ldio-m58iv7a50dp 1s linear infinite;
          animation: ldio-m58iv7a50dp 1s linear infinite; }

.ldio-m58iv7a50dp > div:nth-child(2) {
  border-color: transparent; }

.ldio-m58iv7a50dp > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.ldio-m58iv7a50dp > div:nth-child(2) div:before,
.ldio-m58iv7a50dp > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  top: -4px;
  left: 35px;
  background: #ef7a30;
  border-radius: 50%;
  box-shadow: 0 78px 0 0 #ef7a30; }

.ldio-m58iv7a50dp > div:nth-child(2) div:after {
  left: -4px;
  top: 35px;
  box-shadow: 78px 0 0 0 #ef7a30; }

.loadingio-spinner-dual-ring-jj888ggjf9 {
  width: 37px;
  height: 37px;
  display: inline-block;
  overflow: hidden;
  background: none; }

.ldio-m58iv7a50dp {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(0.37);
          transform: translateZ(0) scale(0.37);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  /* see note above */ }

.ldio-m58iv7a50dp div {
  box-sizing: content-box; }

/* generated by https://loading.io/ */
.ant-dropdown .drop-colored {
  background: -webkit-gradient(linear, left top, right top, from(#c53736), to(#ef7a30));
  background: linear-gradient(to right, #c53736, #ef7a30); }
  .ant-dropdown .drop-colored li span,
  .ant-dropdown .drop-colored li div {
    color: white;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .ant-dropdown .drop-colored li span:hover,
    .ant-dropdown .drop-colored li div:hover {
      color: black; }

.inner-tabs .ant-tabs-bar {
  border: none;
  border-bottom: 1px #bbb solid;
  background: white;
  margin: 0 auto;
  margin-bottom: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.inner-tabs .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  font-size: 1.1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.inner-tabs .ant-tabs-tab-active {
  background: -webkit-gradient(linear, left top, right top, from(#c53736), to(#ef7a30)) !important;
  background: linear-gradient(to right, #c53736, #ef7a30) !important;
  color: white !important;
  font-weight: bold !important; }

.inner-tabs .ant-tabs-ink-bar {
  display: none !important; }

.inner-tabs .ant-tabs-content {
  padding-bottom: 10px; }

.maps {
  width: 200px;
  height: 30px;
  margin: 0.5rem auto 0 auto;
  cursor: pointer; }

.ant-checkbox-wrapper {
  align-items: unset !important; }
  .ant-checkbox-wrapper .ant-checkbox {
    margin-top: 0.25rem;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important; }

textarea.ant-input {
  border: 1px #ef7a30 solid !important;
  border-bottom: 2px #ef7a30 solid !important; }

.slide-container {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px; }
  .slide-container .slider {
    height: 100%; }
    .slide-container .slider .react-slideshow-container {
      height: 100%; }
      .slide-container .slider .react-slideshow-container .nav {
        z-index: 1; }
      .slide-container .slider .react-slideshow-container .react-slideshow-wrapper {
        height: 100%;
        border-radius: 5px; }
        .slide-container .slider .react-slideshow-container .react-slideshow-wrapper .images-wrap {
          height: 100%; }
          .slide-container .slider .react-slideshow-container .react-slideshow-wrapper .images-wrap .each-slide {
            height: 100%; }
            .slide-container .slider .react-slideshow-container .react-slideshow-wrapper .images-wrap .each-slide div {
              height: 100%; }
    .slide-container .slider .indicators {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin: 0 auto; }

.grecaptcha-badge {
  bottom: 60px !important; }

.App {
  text-align: center; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #09d3ac; }

.terModal .head {
  font-weight: bolder;
  font-size: 1.5rem;
  margin-bottom: 0.5rem; }

.terModal .subHead {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem; }

.terModal .talk {
  font-size: 1rem;
  margin-bottom: 0.5rem; }

.recenter, .remarker {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  margin: 10px;
  margin-right: 60px;
  background: white;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  color: #666;
  font-size: 1.3rem;
  outline: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .recenter:hover, .remarker:hover {
    color: #de7e4c; }

.remarker {
  margin-right: 110px; }

.map-search {
  position: absolute;
  top: 0.4rem;
  left: 0.3rem;
  width: 55%;
  height: 50px;
  padding-left: 16px;
  margin-top: 2px;
  margin-bottom: 100px;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; }

.navbar {
  background: -webkit-gradient(linear, left top, right top, from(#d44838), color-stop(#a83039), to(#2d1a3a));
  background: linear-gradient(to right, #d44838, #a83039, #2d1a3a);
  position: fixed !important;
  width: 100vw;
  height: 55px;
  margin-bottom: 0;
  box-shadow: 0px 0px 15px -7px #333;
  z-index: 2; }
  .navbar .container {
    padding: 0;
    display: grid !important;
    grid-template-columns: 2fr 1fr; }
    .navbar .container .controls {
      -webkit-margin-start: 205px;
              margin-inline-start: 205px;
      padding: 0 1.5rem;
      display: flex;
      align-items: center; }
      .navbar .container .controls .ant-input {
        background: #fff !important;
        width: 240px !important;
        border: none !important;
        border: 1px #ef7a30 solid !important;
        border-radius: 50px !important;
        margin: 0 !important; }
      .navbar .container .controls .ant-input-affix-wrapper {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important; }
      .navbar .container .controls .btn-ok {
        margin: 0 1rem;
        padding: 0 1rem;
        border-radius: 50px; }
    .navbar .container .modals {
      display: flex;
      align-items: center;
      justify-self: end;
      color: white; }
      .navbar .container .modals .name {
        margin: 0 0.5rem;
        font-size: 1.3rem;
        padding: 0 1rem;
        color: white;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        text-decoration: none; }
        .navbar .container .modals .name:hover {
          text-decoration: none;
          background: #a83039;
          border-radius: 5px;
          cursor: pointer; }
      .navbar .container .modals i {
        margin: 0 0.5rem;
        font-size: 1.2rem;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .navbar .container .modals i:hover {
          color: #ef7a30;
          cursor: pointer; }
      .navbar .container .modals .img {
        width: 35px;
        height: 35px;
        margin: 0 0.5rem;
        border-radius: 50%;
        box-shadow: 0px 0px 15px -7px #333;
        align-self: center;
        justify-self: center; }

.label-input {
  width: 100%; }
  .label-input p {
    font-size: 1.3rem;
    color: white;
    margin: 0.5rem 0;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content; }
  .label-input input {
    display: block;
    background: none;
    border: 1px white solid;
    width: 60%;
    outline: none;
    padding: 0.3rem 1rem;
    color: white; }

.input-form {
  width: 100%;
  display: flex; }
  .input-form .input {
    background: transparent;
    border: none;
    border-bottom: 2px #de7e4c solid;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    color: #de7e4c;
    font-size: 1rem;
    outline: none; }
  .input-form label {
    color: white;
    font-size: 1.4rem;
    display: block; }
  .input-form .label-input {
    border: 1px white solid;
    background: transparent;
    padding: 8px;
    width: 60%;
    font-size: 1.1rem;
    color: white;
    outline: none; }
  .input-form i {
    padding: 8px;
    font-size: 1.2rem;
    color: #de7e4c; }

.tag {
  background: #ef7a30; }

.user-card, .request-card, .blocked-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 3fr; }
  .user-card .img, .request-card .img, .blocked-card .img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center; }
  .user-card .info, .request-card .info, .blocked-card .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1rem;
    justify-self: self-start;
    text-align: start; }
    .user-card .info .name, .request-card .info .name, .blocked-card .info .name {
      color: #ef7a30;
      font-size: 1.3rem;
      width: 147px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .user-card .info .name:hover, .request-card .info .name:hover, .blocked-card .info .name:hover {
        text-decoration: none;
        color: #de7e4c; }
    .user-card .info .username, .request-card .info .username, .blocked-card .info .username {
      line-height: 1; }
    .user-card .info p, .request-card .info p, .blocked-card .info p {
      margin: 0; }
    .user-card .info .actions, .request-card .info .actions, .blocked-card .info .actions {
      margin: 0;
      margin-top: 10px; }
      .user-card .info .actions i, .request-card .info .actions i, .blocked-card .info .actions i {
        background: #ddd;
        padding: 5px;
        font-size: 1rem;
        width: 30px;
        height: 30px;
        text-align: center;
        margin-right: 10px;
        border-radius: 50%;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .user-card .info .actions i::before, .request-card .info .actions i::before, .blocked-card .info .actions i::before {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%; }
        .user-card .info .actions i:hover, .request-card .info .actions i:hover, .blocked-card .info .actions i:hover {
          color: white;
          background: #ef7a30; }
      .user-card .info .actions .block:hover, .request-card .info .actions .block:hover, .blocked-card .info .actions .block:hover {
        background: #c53736; }
      .user-card .info .actions .follow:hover, .request-card .info .actions .follow:hover, .blocked-card .info .actions .follow:hover {
        background: #0088d2; }

.request-card .decision {
  display: flex;
  margin-top: 10px; }
  .request-card .decision button {
    margin: 0;
    padding: 1px 10px;
    margin-right: 5px;
    font-size: 1.1rem; }

.blocked-card {
  background: #c53736; }
  .blocked-card .info .name {
    color: white; }
    .blocked-card .info .name:hover {
      text-decoration: none;
      color: white; }
  .blocked-card .info button {
    margin: 0;
    padding: 1px 10px;
    margin-right: 5px;
    font-size: 1.1rem;
    text-align: center;
    width: 90%; }

.eventcard, .event-map-card {
  background: white;
  justify-self: center;
  padding: 1rem;
  width: 250px;
  height: 200px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 15px -7px #333;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0.5rem; }
  .eventcard .finished, .event-map-card .finished, .eventcard .running, .event-map-card .running, .eventcard .archived, .event-map-card .archived, .eventcard .profile-event .main .event-showcase .meters .controls .running, .profile-event .main .event-showcase .meters .controls .eventcard .running, .event-map-card .profile-event .main .event-showcase .meters .controls .running, .profile-event .main .event-showcase .meters .controls .event-map-card .running {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1px 1rem;
    background: #c53736;
    color: white;
    border-radius: 0 10px 0 10px; }
  .eventcard .running, .event-map-card .running {
    background: #39a914; }
  .eventcard .archived, .event-map-card .archived {
    background: #aaa;
    color: black; }
  .eventcard .catched, .event-map-card .catched,
  .eventcard .reported,
  .event-map-card .reported {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1px 1rem;
    background: #c53736;
    color: white;
    border-radius: 10px 0 10px 0; }
  .eventcard .catched, .event-map-card .catched {
    background: #a83039; }
  .eventcard:hover, .event-map-card:hover {
    text-decoration: none; }
  .eventcard p, .event-map-card p {
    margin: 0; }
  .eventcard .info, .event-map-card .info {
    align-self: center;
    justify-self: center;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    line-height: 1;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    display: flex;
    flex-direction: column;
    text-align: center; }
    .eventcard .info .name, .event-map-card .info .name {
      font-size: 1.3rem;
      color: #de7e4c;
      text-align: center;
      line-height: 1.5;
      width: 186px;
      margin: auto;
      margin-bottom: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .eventcard .info .by, .event-map-card .info .by {
      color: white;
      font-size: 0.8rem;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: auto;
      margin-bottom: 5px; }
    .eventcard .info .host, .event-map-card .info .host {
      font-size: 1rem;
      color: #de7e4c;
      padding: 0 !important;
      margin: 0 auto !important;
      margin-bottom: 1rem !important;
      background: transparent !important;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .eventcard .info .host:hover, .event-map-card .info .host:hover {
        text-decoration: none;
        color: #c53736; }
    .eventcard .info .del, .event-map-card .info .del {
      margin: 0;
      margin: auto; }
    .eventcard .info:hover .timing, .event-map-card .info:hover .timing {
      display: grid; }
    .eventcard .info .timing, .event-map-card .info .timing {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0.5rem;
      display: none;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .eventcard .info .timing .time, .event-map-card .info .timing .time,
      .eventcard .info .timing .date,
      .event-map-card .info .timing .date,
      .eventcard .info .timing .share,
      .event-map-card .info .timing .share,
      .eventcard .info .timing .view,
      .event-map-card .info .timing .view {
        justify-self: center;
        color: white;
        margin: 0.3rem 0;
        font-size: 0.9rem; }
        .eventcard .info .timing .time i, .event-map-card .info .timing .time i,
        .eventcard .info .timing .date i,
        .event-map-card .info .timing .date i,
        .eventcard .info .timing .share i,
        .event-map-card .info .timing .share i,
        .eventcard .info .timing .view i,
        .event-map-card .info .timing .view i {
          color: #ef7a30;
          margin: 0 0.3rem; }

.event-map-card .info .timing {
  display: grid; }

.metrics {
  display: flex;
  justify-content: center;
  align-items: center; }
  .metrics .sector {
    margin: 0 1rem;
    width: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .metrics .sector .head {
      font-size: 1.3rem;
      font-weight: bold;
      margin: 0;
      color: black; }
      .metrics .sector .head:hover {
        text-decoration: none; }
    .metrics .sector .meter {
      font-size: 1.2rem; }
  .metrics .vert-line {
    width: 1.5px;
    height: 100px;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(#bbb), to(transparent));
    background: linear-gradient(transparent, #bbb, transparent); }

.picname {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .picname .pic {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .picname .pic .img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333; }
    .picname .pic .upload {
      position: absolute;
      bottom: 0;
      right: 0; }
      .picname .pic .upload i {
        font-size: 1.5rem;
        background: white;
        padding: 0.5rem;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0px 0px 15px -7px #333;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .picname .pic .upload i:hover {
          background: #eee;
          color: #ef7a30; }
    .picname .pic i {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 1.5rem;
      background: white;
      padding: 0.5rem;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .picname .pic i:hover {
        background: #eee;
        color: #ef7a30; }
  .picname .info {
    display: flex;
    align-items: center;
    justify-content: center; }
    .picname .info .edit {
      display: flex;
      flex-direction: column;
      margin-top: 15px; }
      .picname .info .edit .name {
        font-size: 1.5rem;
        font-weight: bold;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
        color: #ef7a30; }
        .picname .info .edit .name:hover {
          text-decoration: none;
          color: #f4623a; }
      .picname .info .edit .username {
        font-size: 1.1rem;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        line-height: 1;
        margin: 0 auto; }
    .picname .info i {
      font-size: 1.1rem;
      cursor: pointer;
      line-height: 1;
      margin-left: 10px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .picname .info i:hover {
        background: #eee;
        color: #ef7a30; }

.actions {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  display: flex; }
  .actions button {
    margin: 0 0.5rem;
    padding: 5px 10px; }

.profile-head {
  display: grid;
  grid-template-columns: 1fr 2fr; }
  .profile-head .view {
    align-self: center; }

.personal {
  background: white;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 1rem 0;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333; }
  .personal .head {
    font-weight: bold;
    margin: 0 0.5rem;
    color: #ef7a30; }
  .personal .item {
    display: flex;
    margin: 0.3rem 0; }
  .personal .item-h .head {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .personal .item-h .info {
    text-align: start;
    margin: 0 0.5rem; }

.profile-item {
  background: white;
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  display: flex;
  flex-direction: column; }
  .profile-item .indicator {
    display: flex;
    align-items: center; }
    .profile-item .indicator .head {
      color: #ef7a30;
      font-size: 1.3rem;
      margin: 0 0.5rem; }
    .profile-item .indicator .mutual {
      background: #ddd;
      margin: 0 1rem;
      padding: 0 1rem;
      border-radius: 5px; }
  .profile-item .show {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 1rem 0; }
  .profile-item .show-e {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 1rem 0; }
  .profile-item .btn-ok {
    padding: 3px 1rem;
    margin: 0.5rem 0;
    margin: auto; }

.user-tag {
  display: flex;
  flex-direction: column;
  width: 200px;
  justify-self: center; }
  .user-tag .img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center; }
  .user-tag .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem; }
    .user-tag .info .name {
      color: #ef7a30;
      font-size: 1.5rem;
      text-align: center; }
      .user-tag .info .name:hover {
        text-decoration: none;
        color: #de7e4c; }
    .user-tag .info .username {
      line-height: 1; }

.notif-mute {
  padding: 1rem;
  color: #c53736; }

.profile-event .main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem; }
  .profile-event .main .event-showcase {
    display: flex;
    flex-direction: column; }
    .profile-event .main .event-showcase .img {
      height: 300px;
      box-shadow: 0px 0px 15px -7px #333;
      border-radius: 5px;
      position: relative; }
      .profile-event .main .event-showcase .img .city {
        position: absolute;
        top: 1.5rem;
        left: 0;
        font-size: 1.3rem;
        color: white;
        background: rgba(0, 0, 0, 0.7);
        padding: 5px 2rem; }
        .profile-event .main .event-showcase .img .city i {
          color: #ef7a30;
          font-size: 1.5rem; }
      .profile-event .main .event-showcase .img .rate, .profile-event .main .event-showcase .img .review-wide .rate-rev, .review-wide .profile-event .main .event-showcase .img .rate-rev {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 1.3rem;
        background: rgba(0, 0, 0, 0.7);
        padding: 5px 1rem;
        margin: 0;
        border-radius: 10px 10px 0 0; }
      .profile-event .main .event-showcase .img .views {
        position: absolute;
        bottom: 0;
        left: 0;
        color: #ef7a30;
        font-size: 1.3rem;
        background: rgba(0, 0, 0, 0.7);
        padding: 5px 1rem;
        margin: 0;
        border-radius: 10px 10px 0 0; }
      .profile-event .main .event-showcase .img .status {
        position: absolute;
        top: 0;
        right: 0; }
        .profile-event .main .event-showcase .img .status .state {
          margin: 0.5rem 0;
          padding: 3px 1rem;
          background: #a83039;
          color: white; }
      .profile-event .main .event-showcase .img .show {
        position: absolute;
        bottom: 0;
        right: 7rem;
        font-size: 1.3rem;
        color: white;
        background: rgba(0, 0, 0, 0.7);
        padding: 5px 1rem;
        margin: 0;
        border-radius: 10px 10px 0 0;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .profile-event .main .event-showcase .img .show:hover {
          color: #ef7a30;
          padding-bottom: 1.2rem; }
    .profile-event .main .event-showcase .timing {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      justify-content: center;
      align-items: center; }
      .profile-event .main .event-showcase .timing .time {
        display: flex; }
      .profile-event .main .event-showcase .timing p {
        margin: 0 0.5rem; }
      .profile-event .main .event-showcase .timing i {
        color: #ef7a30; }
      .profile-event .main .event-showcase .timing .name {
        grid-area: name;
        font-size: 1.5rem;
        color: #ef7a30;
        align-self: center; }
    .profile-event .main .event-showcase .meters {
      background: #333;
      padding: 0.5rem;
      width: 100%;
      border-radius: 0 0 10px 10px; }
      .profile-event .main .event-showcase .meters .sep {
        width: 90%;
        height: 1px;
        background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#ccc), to(transparent));
        background: linear-gradient(to right, transparent, #ccc, transparent);
        margin: 0.5rem auto; }
      .profile-event .main .event-showcase .meters .controls {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        color: #ef7a30; }
        .profile-event .main .event-showcase .meters .controls .point {
          cursor: pointer; }
        .profile-event .main .event-showcase .meters .controls .finished, .profile-event .main .event-showcase .meters .controls .eventcard .archived, .eventcard .profile-event .main .event-showcase .meters .controls .archived, .profile-event .main .event-showcase .meters .controls .event-map-card .archived, .event-map-card .profile-event .main .event-showcase .meters .controls .archived, .profile-event .main .event-showcase .meters .controls .running {
          padding: 1px 1rem;
          background: #c53736;
          color: white;
          border-radius: 5px;
          margin: 0 0.5rem; }
        .profile-event .main .event-showcase .meters .controls .running {
          background: #39a914; }
        .profile-event .main .event-showcase .meters .controls .btn-gr,
        .profile-event .main .event-showcase .meters .controls .btn-del {
          padding: 0.1rem 1rem;
          margin: 0 0.5rem; }
        .profile-event .main .event-showcase .meters .controls button {
          padding: 0.1rem 1rem;
          margin: 0 0.5rem; }
        .profile-event .main .event-showcase .meters .controls p {
          margin: 0 0.5rem; }
        .profile-event .main .event-showcase .meters .controls .sett {
          margin: 0 0.5rem; }
        .profile-event .main .event-showcase .meters .controls .likes,
        .profile-event .main .event-showcase .meters .controls .sett {
          color: white;
          cursor: pointer; }
        .profile-event .main .event-showcase .meters .controls .wishlist, .profile-event .main .event-showcase .meters .controls .wishlist-active {
          background: #333;
          padding: 5px;
          font-size: 1.3rem;
          width: 39px;
          height: 39px;
          text-align: center;
          border-radius: 50%;
          color: white;
          cursor: pointer;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
          .profile-event .main .event-showcase .meters .controls .wishlist::before, .profile-event .main .event-showcase .meters .controls .wishlist-active::before {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%; }
        .profile-event .main .event-showcase .meters .controls .wishlist-active {
          color: #ef7a30; }
    .profile-event .main .event-showcase .share {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: 0;
      display: flex;
      color: black;
      padding-top: 0.3rem;
      align-items: center;
      margin: auto; }
      .profile-event .main .event-showcase .share .head {
        color: #ef7a30;
        font-size: 1.3rem; }
      .profile-event .main .event-showcase .share .sh-icon {
        margin: 0 0.5rem; }
  .profile-event .main .data {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.75rem; }
    .profile-event .main .data p {
      margin: 0;
      font-size: 1.1rem; }
    .profile-event .main .data .item {
      align-self: center; }
      .profile-event .main .data .item .hov:hover {
        color: #ef7a30 !important; }
      .profile-event .main .data .item .bold,
      .profile-event .main .data .item i {
        font-size: 1.3rem;
        color: #ef7a30; }
      .profile-event .main .data .item .det {
        text-align: center; }
      .profile-event .main .data .item .grey {
        background: #eee;
        padding: 0 0.5rem;
        margin: 0 0.5rem;
        border-radius: 5px; }
      .profile-event .main .data .item .keyword {
        display: inline-block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background: -webkit-gradient(linear, left top, right top, from(#ef7a30), to(#c53736));
        background: linear-gradient(to right, #ef7a30, #c53736);
        padding: 3px 0.5rem;
        color: white;
        margin: 0.5rem;
        border-radius: 5px; }
    .profile-event .main .data .key-show {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .profile-event .main .data .allcol {
      grid-column: 1 / 3; }
      .profile-event .main .data .allcol .det {
        display: block; }
  .profile-event .main .host {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    padding: 1rem;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 1rem auto;
    align-items: center; }
    .profile-event .main .host .ch-btn {
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .profile-event .main .host .ch-btn:hover {
        color: #ef7a30; }
    .profile-event .main .host .img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
      align-self: center;
      justify-self: center; }
    .profile-event .main .host .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 1rem; }
      .profile-event .main .host .info .name {
        color: #ef7a30;
        font-size: 1.5rem; }
        .profile-event .main .host .info .name:hover {
          text-decoration: none;
          color: #de7e4c; }
      .profile-event .main .host .info .username {
        line-height: 1; }
      .profile-event .main .host .info p {
        margin: 0; }
  .profile-event .main .gr-loc {
    grid-column: 1 / 3; }
  .profile-event .main .location .label {
    color: #ef7a30;
    font-size: 1.5rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0.5rem auto 0.5rem auto; }
  .profile-event .main .location .mapping {
    height: 400px; }
  .profile-event .main .network {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.profile-event .reviews {
  margin-top: 1rem; }
  .profile-event .reviews .label {
    color: #ef7a30;
    font-size: 1.5rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-bottom: 1rem; }
  .profile-event .reviews .line {
    margin: 1rem auto; }
  .profile-event .reviews .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem; }

.profile-event .ant-tabs .ant-tabs-bar {
  border: none;
  border-bottom: 1px #bbb solid;
  background: white;
  margin: 0 auto;
  margin-bottom: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.profile-event .ant-tabs .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  font-size: 1.1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  padding: 3px 2rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.profile-event .ant-tabs .ant-tabs-tab-active {
  background: -webkit-gradient(linear, left top, right top, from(#c53736), to(#ef7a30)) !important;
  background: linear-gradient(to right, #c53736, #ef7a30) !important;
  color: white !important;
  font-weight: bold !important; }

.profile-event .ant-tabs .ant-tabs-ink-bar {
  display: none !important; }

.profile-event .ant-tabs .ant-tabs-content {
  padding-bottom: 10px; }

.profile-event .reports {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  justify-content: center;
  align-items: center; }
  .profile-event .reports i {
    color: #ef7a30;
    padding: 0 1rem;
    font-size: 4rem; }
  .profile-event .reports .info {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px; }
  .profile-event .reports .rep {
    color: #ef7a30;
    font-weight: bold;
    font-size: 1.3rem; }
  .profile-event .reports p {
    margin: 0; }

.friends .head {
  color: #ef7a30;
  font-size: 1.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.friends .items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem; }
  .friends .items .info {
    display: flex;
    flex-direction: column;
    background: white;
    justify-self: center;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    padding: 1rem 0;
    width: 130px;
    margin: 0.5rem 0; }
    .friends .items .info .img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
      align-self: center;
      justify-self: center; }
    .friends .items .info .name {
      color: black;
      font-size: 1.1rem;
      width: 120px;
      text-align: center;
      margin: 10px auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .friends .items .info .name:hover {
        text-decoration: none;
        color: #de7e4c; }
    .friends .items .info .btn-ok,
    .friends .items .info .btn-mess {
      margin: 0;
      padding: 3px 10px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: auto; }

.review-card {
  background: white;
  justify-self: center;
  padding: 1rem;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0.5rem; }
  .review-card .img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center; }
  .review-card .feed {
    grid-column: 1 / 3;
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    max-height: 150px !important;
    overflow: auto;
    margin: 0; }
  .review-card .info {
    padding: 1rem 0 0 0;
    align-self: center; }
    .review-card .info .name {
      font-size: 1.3rem;
      line-height: 1;
      color: #de7e4c; }
      .review-card .info .name:hover {
        text-decoration: none;
        color: #f4623a; }
    .review-card .info .username {
      color: black;
      margin: 0; }
    .review-card .info .rate, .review-card .info .review-wide .rate-rev, .review-wide .review-card .info .rate-rev {
      color: #de7e4c; }
      .review-card .info .rate li, .review-card .info .review-wide .rate-rev li, .review-wide .review-card .info .rate-rev li {
        margin: 2px; }

.review-wide {
  background: white;
  justify-self: center;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  position: relative;
  margin-bottom: 1rem; }
  .review-wide .ident {
    display: flex;
    margin-bottom: 1rem; }
    .review-wide .ident .img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
      -webkit-margin-end: 2rem;
              margin-inline-end: 2rem; }
    .review-wide .ident .info {
      padding: 1rem 0 0 0; }
      .review-wide .ident .info .name {
        font-size: 1.3rem;
        line-height: 1;
        color: #de7e4c; }
        .review-wide .ident .info .name:hover {
          text-decoration: none;
          color: #f4623a; }
      .review-wide .ident .info .username {
        color: black;
        margin: 0; }
  .review-wide .feed {
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    margin: 0; }
  .review-wide .rate, .review-wide .rate-rev {
    color: #de7e4c;
    position: absolute;
    top: 1rem;
    right: 1rem; }
    .review-wide .rate li, .review-wide .rate-rev li {
      margin: 2px; }
  .review-wide .rate-rev {
    right: unset;
    left: 1rem; }

.rate, .review-wide .rate-rev {
  color: #de7e4c; }
  .rate li, .review-wide .rate-rev li {
    margin: 2px; }

.div-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; }
  .div-modal .modal-items {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    padding: 1rem;
    position: relative;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .div-modal .modal-items .head {
      color: #ef7a30;
      font-size: 1.5rem; }
    .div-modal .modal-items .close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: black;
      cursor: pointer; }
      .div-modal .modal-items .close:hover {
        color: #ef7a30; }

.filter {
  display: none; }

.filter-active {
  background: white;
  display: block;
  width: 100%;
  margin: 2.5rem auto 0.5rem auto;
  padding: 1.5rem;
  border-radius: 10px;
  grid-column: 1 / 4; }

.filter-elem {
  display: flex;
  justify-content: center;
  align-items: center; }
  .filter-elem .label-select,
  .filter-elem .sorting {
    width: 30%;
    margin: 0 0.5rem; }
    .filter-elem .label-select .label,
    .filter-elem .sorting .label {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: auto; }

.search {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  float: right;
  grid-column: 1 / 4; }
  .search .fa {
    align-self: center;
    font-size: 1.5rem;
    color: #de7e4c; }
  .search .ant-select {
    width: 15rem;
    -webkit-margin-start: 1rem;
            margin-inline-start: 1rem; }
    .search .ant-select .ant-select-selection {
      border: none;
      background: white;
      margin: 0 !important; }
  .search .fa-filter {
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .search .fa-filter:hover {
      color: #f4623a; }
  .search .search-bar {
    padding: 0.3rem 1rem;
    -webkit-margin-start: 1rem;
            margin-inline-start: 1rem;
    border: none;
    border-radius: 5px;
    width: 20rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    outline: none; }

.contact {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: auto; }
  .contact .head-main {
    margin: 0; }
  .contact .email {
    display: flex;
    align-items: center;
    padding: 0.5rem 0; }
    .contact .email i {
      color: #ef7a30;
      padding: 0 1rem; }
  .contact .ant-input {
    border-radius: 10px !important;
    border-bottom: none !important;
    border: 1px #999 solid !important; }
  .contact .ant-input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.7); }
  .contact .ant-input::-moz-placeholder {
    color: rgba(0, 0, 0, 0.7); }
  .contact .ant-input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.7); }
  .contact .ant-input::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.7); }
  .contact .ant-input::placeholder {
    color: rgba(0, 0, 0, 0.7); }
  .contact .lower {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 0.3fr;
    grid-gap: 1rem; }
  .contact .talk {
    max-width: 500px;
    text-align: center; }
  .contact .btn-ok {
    margin: 0;
    padding: 0 2rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: center;
    border-radius: 10px !important; }

.notif-item, .notif-full {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid !important;
  grid-template-columns: 1fr 4fr;
  border-bottom: 0.5px #ccc solid;
  width: 20rem;
  white-space: unset; }
  .notif-item:hover, .notif-full:hover {
    text-decoration: none; }
    .notif-item:hover .info .desc, .notif-full:hover .info .desc {
      color: #f4623a !important; }
  .notif-item .img, .notif-full .img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center; }
  .notif-item .info, .notif-full .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1rem;
    justify-self: self-start;
    text-align: start;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .notif-item .info .name, .notif-full .info .name {
      color: black;
      font-weight: bold;
      font-size: 1.2rem; }
    .notif-item .info .desc, .notif-full .info .desc {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      white-space: normal;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }

.notif-full {
  width: 100%;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 10px; }

.notif-contain {
  position: relative;
  display: flex; }

.delete {
  color: black;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .delete:hover {
    color: #ef7a30; }

.read {
  background: #eee; }

.landing-foot {
  background: #212529;
  overflow: hidden;
  width: 100%;
  z-index: 3;
  position: relative; }
  .landing-foot .elements {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    .landing-foot .elements .link {
      color: white;
      padding: 1rem;
      font-size: 1.3rem;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .landing-foot .elements .link:hover {
        color: #ef7a30;
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px); }
  .landing-foot .line {
    width: 50%;
    margin: 0 auto;
    height: 0.5px; }
  .landing-foot .copyr {
    color: white;
    padding: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto; }
  .landing-foot .social {
    padding-top: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    color: white;
    align-items: center; }
    .landing-foot .social i {
      font-size: 2rem;
      padding: 0 1rem;
      color: white;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .landing-foot .social .face:hover {
      color: #0573e7; }
    .landing-foot .social .insta:hover {
      color: #f25445; }
    .landing-foot .social .twitt:hover {
      color: #32ccfe; }

.foot-cont {
  width: 100%;
  display: flex;
  justify-content: center; }

.primary-footer {
  background: white;
  padding: 0 3rem;
  box-shadow: 0px 0px 15px -7px #333;
  border-radius: 35px 35px 0 0;
  overflow: hidden;
  width: 80%;
  z-index: 2;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between; }
  .primary-footer .elements {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    .primary-footer .elements .img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      align-self: center;
      justify-self: center; }
    .primary-footer .elements .link {
      color: #666;
      padding: 1rem;
      font-size: 1rem;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .primary-footer .elements .link:hover {
        color: #ef7a30;
        font-weight: bold;
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px); }
  .primary-footer .social {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    display: flex;
    color: black;
    align-items: center; }
    .primary-footer .social i {
      font-size: 1.5rem;
      padding: 0 1rem;
      color: #666;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .primary-footer .social i:hover {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px); }
    .primary-footer .social .face:hover {
      color: #0573e7; }
    .primary-footer .social .insta:hover {
      color: #f25445; }
    .primary-footer .social .twitt:hover {
      color: #32ccfe; }

.link-head {
  display: flex;
  align-items: center; }
  .link-head .btn-ok {
    padding: 0 1rem;
    font-size: 1rem; }

.land-nav, .land-nav-op {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  direction: ltr;
  width: 100%;
  position: fixed;
  z-index: 3;
  padding: 0 6rem;
  background: white;
  box-shadow: 0px 0px 15px -7px #333;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  top: 0;
  display: flex;
  justify-content: space-between; }
  .land-nav .elements, .land-nav-op .elements {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    .land-nav .elements i, .land-nav-op .elements i {
      margin: 0 0.5rem;
      font-size: 1.2rem;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .land-nav .elements i:hover, .land-nav-op .elements i:hover {
        color: #ef7a30;
        cursor: pointer; }
    .land-nav .elements .img, .land-nav-op .elements .img {
      width: 35px;
      height: 35px;
      margin: 0 0.5rem;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
      align-self: center;
      justify-self: center; }
    .land-nav .elements .link, .land-nav-op .elements .link {
      color: #666;
      padding: 1rem;
      font-size: 1rem;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .land-nav .elements .link:hover, .land-nav-op .elements .link:hover {
        color: #ef7a30;
        font-weight: bold;
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px); }
  .land-nav .brand, .land-nav-op .brand {
    display: flex;
    align-items: center; }
    .land-nav .brand .img, .land-nav-op .brand .img {
      width: 50px;
      height: 45px; }
    .land-nav .brand .name, .land-nav-op .brand .name {
      width: 120px;
      height: 40px; }
  .land-nav:hover, .land-nav-op:hover {
    opacity: 1; }

.land-nav-op {
  opacity: 0.8; }

@media only screen and (max-width: 1024px) {
  .land-nav, .land-nav-op {
    padding: 0.4rem; }
    .land-nav .elements, .land-nav-op .elements {
      display: none; }
  .contact .lower {
    grid-template-columns: 1fr 1fr; }
    .contact .lower .btn-ok {
      grid-column: 1 / 3; } }

.sign-in .head-main {
  margin: 0.8rem 0; }

.sign-in form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .sign-in form .input-form {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .sign-in form .input-form .input {
      width: 300px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .sign-in form .input-form .input:focus {
        width: 350px; }

.intro {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  padding-top: 7rem; }
  .intro .upper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .intro .upper .parag {
      font-size: 1rem; }
  .intro .btn-ok {
    color: white; }
  .intro .map {
    width: 90%;
    height: 90%;
    border-radius: 0; }

.read-more {
  padding: 1rem; }
  .read-more .container {
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 1.3rem; }
    .read-more .container .section {
      display: flex;
      justify-content: center;
      align-items: center; }
      .read-more .container .section .talk .slogan {
        font-size: 2rem;
        text-align: center; }
      .read-more .container .section .about {
        text-align: justify;
        line-height: 2;
        -webkit-margin-end: 2rem;
                margin-inline-end: 2rem; }

.service {
  padding: 2rem; }
  .service .head-main {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    color: black; }
  .service .section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    padding: 1rem; }
    .service .section .item {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .service .section .item i {
        font-size: 3rem;
        color: #de7e4c; }
      .service .section .item .head {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        margin: 0.5rem 0; }
      .service .section .item .talk {
        max-width: 250px;
        text-align: center; }

.categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr); }
  .categories .item {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; }
    .categories .item:hover::before {
      opacity: 0.8; }
    .categories .item:hover .head,
    .categories .item:hover .type {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      opacity: 1; }
    .categories .item::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #de7e4c;
      opacity: 0;
      z-index: 1;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .categories .item .head,
    .categories .item .type {
      color: white;
      font-size: 1.3rem;
      z-index: 2;
      opacity: 0;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .categories .item .head {
      font-weight: bold; }

.contact-home {
  padding: 1rem;
  grid-gap: 1rem; }
  .contact-home .contact {
    justify-self: center; }

.home-page .copy {
  background: #333;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center; }

.get-app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem; }
  .get-app .label {
    color: white;
    font-size: 2rem; }
  .get-app .imgs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .get-app .imgs .img {
      width: 300px;
      height: 100px;
      margin: 1rem 3rem;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      cursor: pointer; }
      .get-app .imgs .img:hover {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px); }

.top-mom {
  padding: 2rem; }
  .top-mom .head-main {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    color: black; }
  .top-mom .slick-arrow::before {
    color: black; }
  .top-mom .slick-slide .event-map-card {
    margin: auto; }

.white-card {
  box-shadow: 0px 0px 15px -7px #333;
  padding: 2rem;
  border-radius: 10px;
  width: 60%;
  background: rgba(0, 0, 0, 0.6);
  height: 90vh;
  overflow: auto; }

@media only screen and (max-width: 1024px) {
  .griding {
    grid-template-columns: 1fr; }
  .sign-in {
    display: none; }
  .read-more .container .section .talk .about {
    text-align: center;
    margin: 0; }
  .read-more .container .section img {
    display: none; }
  .categories {
    grid-template-columns: 1fr 1fr; }
  .contact-home {
    grid-template-columns: 1fr; }
    .contact-home .contact {
      width: 100%; }
    .contact-home .head {
      width: 100%; }
      .contact-home .head img {
        width: 200px !important; }
  .service .section {
    grid-template-columns: 1fr 1fr; }
  .get-app .imgs {
    display: flex;
    flex-direction: column; }
  .intro {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .intro .map {
      margin-bottom: 2rem; } }

@media only screen and (max-width: 600px) {
  .categories {
    grid-template-columns: 1fr; }
  .service .section {
    grid-template-columns: 1fr; } }

.ant-input,
.ant-time-picker-input {
  background: transparent !important;
  color: #de7e4c !important;
  border: none !important;
  border-bottom: 2px #de7e4c solid !important;
  border-radius: 5px !important;
  font-size: 1rem !important;
  height: 2rem !important;
  padding: 4px 11px !important;
  margin: 0.5rem 0 !important;
  outline: none !important; }
  .ant-input:focus,
  .ant-time-picker-input:focus {
    box-shadow: none !important;
    outline: none !important; }

.dis-input .ant-input-disabled {
  background-color: transparent !important;
  border-bottom: 2px #ddd solid; }

.dis-input i {
  color: #ddd !important; }
  .dis-input i:hover {
    color: #ddd !important; }

.ant-input-affix-wrapper .ant-input {
  padding: 4px 3rem !important; }

.ant-input-affix-wrapper i {
  font-size: 1rem;
  color: #de7e4c; }
  .ant-input-affix-wrapper i:hover {
    color: #de7e4c; }

.normal-input .ant-input {
  padding: 4px 11px !important; }

.prefix-input .ant-input {
  padding: 4px 11px 4px 3rem !important; }

.sufix-input .ant-input {
  padding: 4px 3rem 4px 11px !important; }

.pass-noshow .ant-input-suffix .ant-input-password-icon {
  display: none; }

.select-icon {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1rem;
  float: left;
  color: #de7e4c;
  margin: 0.5rem 0;
  padding: 6px 11px 6px 12px;
  border-bottom: 2px #de7e4c solid;
  border-bottom-left-radius: 5px !important; }

.ant-input-group {
  width: 100%;
  display: flex !important; }
  .ant-input-group .ant-select .ant-select-selection {
    border-bottom-right-radius: 5px !important; }

.ant-select {
  width: 100%; }
  .ant-select .ant-select-selection {
    background: transparent;
    color: #de7e4c;
    border: none;
    border-bottom: 2px #de7e4c solid;
    border-radius: 5px;
    font-size: 1rem;
    margin: 0.5rem 0;
    outline: none;
    box-shadow: none !important;
    padding: 4px 11px; }
    .ant-select .ant-select-selection:focus, .ant-select .ant-select-selection:active {
      outline: none; }
    .ant-select .ant-select-selection .ant-select-selection__rendered {
      height: 22px;
      margin: 0;
      line-height: 1.5; }
    .ant-select .ant-select-selection .ant-select-arrow {
      color: #de7e4c; }

.ant-select-disabled .ant-select-selection {
  background-color: transparent;
  border-bottom: 2px #ddd solid; }
  .ant-select-disabled .ant-select-selection .ant-select-arrow {
    color: #ddd; }

.dis-group .select-icon {
  color: #ddd;
  border-bottom: 2px #ddd solid; }

.ant-tag {
  background: -webkit-gradient(linear, left top, right top, from(#c53736), to(#ef7a30));
  background: linear-gradient(to right, #c53736, #ef7a30);
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem;
  font-size: 1.1rem;
  color: white;
  margin: 0.2rem;
  cursor: pointer; }
  .ant-tag:hover {
    background: -webkit-gradient(linear, left top, right top, from(#c53736), color-stop(#ef7a30), to(#ef7a30)) !important;
    background: linear-gradient(to right, #c53736, #ef7a30, #ef7a30) !important;
    opacity: 1 !important; }
  .ant-tag i {
    font-size: 1.1rem;
    padding: 0 0.5rem; }
    .ant-tag i:hover {
      color: white !important; }

.sign-up {
  width: 400px;
  margin: auto;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px; }
  .sign-up .ant-tabs-bar {
    width: 400px;
    margin: auto;
    border: none;
    color: white;
    border-radius: 5px; }
  .sign-up .ant-tabs-nav {
    width: 100%; }
    .sign-up .ant-tabs-nav .ant-tabs-tab {
      background: rgba(0, 0, 0, 0.7);
      border-radius: 5px;
      margin: 0;
      width: 50%;
      font-size: 1.1rem;
      text-align: center; }
    .sign-up .ant-tabs-nav .ant-tabs-tab-active {
      background: none; }
    .sign-up .ant-tabs-nav .ant-tabs-ink-bar {
      width: 50% !important;
      display: none !important; }
  .sign-up form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .sign-up form .terms {
      text-align: center;
      display: inline-block;
      font-size: 0.8rem; }
    .sign-up form .anticon-calendar {
      color: #de7e4c; }
    .sign-up form .btn-ok {
      margin-bottom: 0; }
    .sign-up form .validate {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .sign-up form .validate .indicator {
        padding: 0 0 0 0.5rem; }
        .sign-up form .validate .indicator i {
          font-size: 1.3rem; }
        .sign-up form .validate .indicator .valid {
          color: #6ac045; }
        .sign-up form .validate .indicator .invalid {
          color: #c53736; }

.issue {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: url("https://res.cloudinary.com/momants/image/upload/v1589975144/Issue_fk61hc.jpg") no-repeat center bottom/cover;
  padding: 40px;
  border-radius: 10px; }
  .issue .info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px; }
    .issue .info .head {
      text-align: center; }
    .issue .info .talk {
      text-align: center;
      max-width: 70%; }

.issue-head {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0;
  font-size: 2.7rem; }

.issue-body {
  font-size: 1.6rem;
  color: white;
  max-width: 800px; }

.btn-issue {
  padding: 10px 30px;
  font-size: 1.3rem;
  background: #ef7a30;
  color: white;
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 20px 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin: 1rem 0; }
  .btn-issue:focus {
    outline: none; }
  .btn-issue i {
    color: white;
    padding: 0 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .btn-issue:hover {
    background: #f4623a;
    text-decoration: none;
    color: black;
    border-radius: 0; }
    .btn-issue:hover i {
      color: black; }

.issue-action {
  color: white;
  margin: 1rem 0;
  font-size: 1.2rem;
  text-align: start; }
  .issue-action .head {
    font-size: 1.5rem;
    font-weight: bold;
    color: white; }

@media only screen and (max-width: 900px) {
  .issue {
    width: 95% !important; }
    .issue .info .talk {
      max-width: 90% !important; }
    .issue .label-input input {
      width: 100%; }
  .issue-head {
    width: 100%; } }

.map {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  display: grid !important;
  grid-template-columns: repeat(1, 1fr) !important;
  grid-gap: 1rem !important;
  height: 65vh; }

.other .head {
  color: black;
  font-size: 1.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem auto; }

.other .line {
  margin: auto; }

.other .items {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem; }

.pic-section {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%; }
  .pic-section .pic {
    margin-bottom: 1rem; }

.info-section {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem; }

.edit-profile {
  direction: ltr; }
  .edit-profile .btn-ok {
    grid-column: 1 / 3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto; }

.chat-ch {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: 1px #ef7a30 solid;
  border-bottom: 2px #ef7a30 solid;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .chat-ch .he {
    font-size: 1.2rem;
    margin: 0;
    color: black; }

.event-form {
  display: grid;
  grid-template-columns: 1fr 0.95fr;
  grid-gap: 1rem; }
  .event-form .data {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .event-form .data .btn-ok {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: auto; }
    .event-form .data .info {
      font-size: 0.8rem;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: auto; }
    .event-form .data .head {
      color: #ef7a30;
      font-size: 1.5rem;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      -webkit-margin-start: 1rem;
              margin-inline-start: 1rem;
      margin: auto; }
  .event-form .other {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem; }
    .event-form .other .images {
      background: white;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -7px #333;
      padding: 1rem;
      padding: 1rem 0.5rem;
      background: #333; }
      .event-form .other .images .head {
        color: #ef7a30;
        font-size: 1.5rem;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        -webkit-margin-start: 1rem;
                margin-inline-start: 1rem;
        margin: 0 auto 1rem auto;
        line-height: 1; }
      .event-form .other .images .clearfix {
        height: 104px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
        .event-form .other .images .clearfix .ant-upload-picture-card-wrapper {
          height: 104px;
          display: flex; }
        .event-form .other .images .clearfix .ant-upload,
        .event-form .other .images .clearfix .ant-upload-list-item {
          margin: 0 0.3rem; }
      .event-form .other .images .section {
        display: flex; }
      .event-form .other .images .temp-images {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: flex; }
        .event-form .other .images .temp-images .image {
          width: 102.4px;
          height: 102.4px;
          border-radius: 3px;
          margin: 0 0.3rem;
          display: inline-block;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          background: black;
          cursor: pointer;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
          .event-form .other .images .temp-images .image:hover {
            opacity: 0.8; }
            .event-form .other .images .temp-images .image:hover i {
              color: white;
              opacity: 1; }
          .event-form .other .images .temp-images .image i {
            position: absolute;
            font-size: 1.5rem;
            color: white;
            opacity: 0;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out; }
    .event-form .other .keywords {
      background: white;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -7px #333;
      padding: 1rem; }
    .event-form .other .map {
      height: 270px; }



.chatting {
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  grid-gap: 1rem;
  padding: 1rem 0; }
  .chatting .chat,
  .chatting .chats {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    padding: 1rem;
    height: 80vh; }
  .chatting .chat .chat-head {
    height: 20%;
    position: relative;
    display: grid;
    grid-template-columns: 1.5fr 5fr 0.5fr;
    grid-gap: 1rem; }
    .chatting .chat .chat-head .delete {
      color: #bbb;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      justify-self: center;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }
      .chatting .chat .chat-head .delete:hover {
        color: #c53736; }
    .chatting .chat .chat-head .img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
      align-self: center;
      justify-self: center; }
    .chatting .chat .chat-head i {
      font-size: 1.5rem; }
    .chatting .chat .chat-head .info {
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .chatting .chat .chat-head .info .name {
        color: #ef7a30;
        font-size: 1.5rem;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
        .chatting .chat .chat-head .info .name:hover {
          text-decoration: none; }
    .chatting .chat .chat-head .line {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
      width: 100%; }
  .chatting .chat .chat-body {
    height: 70%;
    background: #eee;
    padding: 1rem 0 1rem 1rem;
    border-radius: 15px;
    overflow: auto;
    direction: ltr; }
    .chatting .chat .chat-body .load {
      font-size: 1.2rem;
      color: black;
      border: none;
      outline: none;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: auto;
      background: transparent;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .chatting .chat .chat-body .load:hover {
        color: #ef7a30; }
    .chatting .chat .chat-body .own-mess {
      background: #de7e4c;
      color: white;
      margin: 0.5rem 1rem 0 auto;
      word-wrap: break-word; }
    .chatting .chat .chat-body .own-time {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-size: 0.8rem;
      margin: 0 0 0 auto;
      color: #ddd; }
    .chatting .chat .chat-body .other-time {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-size: 0.8rem;
      color: #999; }
    .chatting .chat .chat-body .other-mess {
      margin: 0.5rem;
      margin-bottom: 0;
      margin-left: 0;
      background: white;
      word-wrap: break-word; }
    .chatting .chat .chat-body .own-mess,
    .chatting .chat .chat-body .other-mess {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 3px 1rem;
      border-radius: 10px;
      max-width: 350px; }
  .chatting .chat .chat-text form {
    height: 10%;
    display: flex;
    align-items: center;
    padding: 0 1rem; }
    .chatting .chat .chat-text form .send {
      font-size: 1.3rem;
      padding: 1rem;
      color: #aaa;
      background: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .chatting .chat .chat-text form .send:hover {
        color: #ef7a30; }
  .chatting .chat .chat-now {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; }
    .chatting .chat .chat-now .logo {
      width: 200px; }
    .chatting .chat .chat-now .chat-lbl {
      font-size: 1.5rem;
      color: #bbb; }
  .chatting .chats {
    padding: 0;
    overflow: auto; }
    .chatting .chats .ant-input {
      background: #fff !important;
      border: none !important;
      border: 1px #ef7a30 solid !important;
      border-radius: 50px !important; }

.chat-item, .chat-item-active {
  text-decoration: none;
  width: 100%;
  display: grid !important;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 1rem;
  padding-bottom: 0;
  border-radius: 5px;
  margin: 0 !important;
  direction: ltr;
  cursor: pointer; }
  .chat-item:hover, .chat-item-active:hover {
    text-decoration: none;
    background: #eee; }
  .chat-item .img, .chat-item-active .img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333; }
  .chat-item .info, .chat-item-active .info {
    width: 100%; }
    .chat-item .info .name, .chat-item-active .info .name {
      color: #ef7a30;
      font-size: 1.1rem; }
    .chat-item .info .activity, .chat-item-active .info .activity {
      display: grid;
      grid-template-columns: 2fr 0.5fr; }
      .chat-item .info .activity .latest, .chat-item-active .info .activity .latest {
        color: #bbb;
        max-width: 202px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .chat-item .info .activity .seen, .chat-item-active .info .activity .seen {
        color: white;
        background: #ef7a30;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        justify-self: center;
        border-radius: 50%; }
    .chat-item .info .line, .chat-item-active .info .line {
      margin: 0;
      margin-top: 1rem;
      width: 100%;
      height: 1px;
      background: #ddd; }

.chat-item-active {
  background: #eee; }
  .chat-item-active:hover {
    background: #eee; }

.search-top {
  padding: 0 1rem; }

.reviews .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem; }
  .reviews .header .head {
    font-size: 1.5rem;
    color: #de7e4c; }
  .reviews .header .count {
    -webkit-margin-start: 1rem;
            margin-inline-start: 1rem; }

.reviews .line-f {
  margin: 0 0 1rem 0; }

.user-review-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }
  .user-review-card .remove {
    position: absolute;
    top: 5px;
    right: 10px; }
    .user-review-card .remove i {
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .user-review-card .remove i:hover {
        color: #ef7a30; }
  .user-review-card .feed {
    grid-column: 1 / 3;
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    height: 150px !important;
    overflow: auto;
    margin: 0;
    width: 100%; }
  .user-review-card .by {
    margin: 0;
    font-size: 0.9rem; }
  .user-review-card .name {
    font-size: 1.5rem;
    line-height: 1;
    color: #de7e4c;
    margin-bottom: 0.5rem; }
    .user-review-card .name:hover {
      text-decoration: none;
      color: #f4623a; }
  .user-review-card .info {
    padding: 0 0 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .user-review-card .info .host-name {
      font-size: 1.3rem;
      line-height: 1;
      color: #de7e4c; }
      .user-review-card .info .host-name:hover {
        text-decoration: none;
        color: #f4623a; }
    .user-review-card .info .username {
      color: black;
      margin: 0; }
    .user-review-card .info .rate {
      color: #de7e4c; }
      .user-review-card .info .rate li {
        margin: 2px; }

.reports .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem; }
  .reports .header .head {
    font-size: 1.5rem;
    color: #de7e4c; }

.reports .line-f {
  margin: 0 0 1rem 0; }

.report-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; }
  .report-card .remove {
    position: absolute;
    top: 5px;
    right: 10px; }
    .report-card .remove i {
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .report-card .remove i:hover {
        color: #ef7a30; }
  .report-card .name {
    font-size: 1.5rem;
    line-height: 1;
    color: #de7e4c;
    margin-bottom: 0.5rem; }
    .report-card .name:hover {
      text-decoration: none;
      color: #f4623a; }
  .report-card .rep {
    grid-column: 1 / 3;
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    height: 150px !important;
    overflow: auto;
    margin: 0;
    margin-top: 1rem;
    width: 100%; }

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .about .head-main {
    margin: 0.5rem; }
  .about .about-feed {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-gap: 1rem;
    background: white;
    border-radius: 20px;
    box-shadow: 0px 0px 15px -7px #333; }
    .about .about-feed .feedback {
      background: -webkit-gradient(linear, left top, right top, from(#c53736), to(#ef7a30));
      background: linear-gradient(to right, #c53736, #ef7a30);
      padding: 1rem;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .about .about-feed .feedback .head {
        color: white;
        font-size: 1.3rem; }
      .about .about-feed .feedback form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%; }
        .about .about-feed .feedback form .btn-ok {
          margin: 0.5rem;
          padding: 0 1rem;
          border-radius: 50px;
          background: -webkit-gradient(linear, left top, right top, from(#ef7a30), to(#c53736)) !important;
          background: linear-gradient(to right, #ef7a30, #c53736) !important; }
        .about .about-feed .feedback form .ant-input {
          text-align: center;
          border-color: white !important;
          color: white !important; }
        .about .about-feed .feedback form .ant-rate .ant-rate-star {
          margin-right: 8px !important; }
        .about .about-feed .feedback form .ant-rate .ant-rate-star-full i {
          color: #ffe46c; }
    .about .about-feed .about {
      padding: 1rem;
      line-height: 2;
      text-align: justify; }
  .about .links {
    display: flex;
    padding: 1rem; }
    .about .links .link {
      padding: 0 0.5rem;
      cursor: pointer; }
  .about .copy {
    font-size: 0.85rem; }

.login-contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .login-contact .contact {
    background: radial-gradient(white, transparent, transparent);
    box-shadow: none; }

.settings .head-main {
  color: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 1rem auto; }
  .settings .head-main i {
    color: #ef7a30; }

.settings .main-settings,
.settings .user-settings,
.settings .comp-settings {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  width: 90%; }
  .settings .main-settings .sett-item,
  .settings .user-settings .sett-item,
  .settings .comp-settings .sett-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .settings .main-settings .sett-item .desc,
    .settings .user-settings .sett-item .desc,
    .settings .comp-settings .sett-item .desc {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
    .settings .main-settings .sett-item .head,
    .settings .user-settings .sett-item .head,
    .settings .comp-settings .sett-item .head {
      font-weight: bold;
      font-size: 1.3rem;
      -webkit-margin-end: 1rem;
              margin-inline-end: 1rem; }
  .settings .main-settings i,
  .settings .user-settings i,
  .settings .comp-settings i {
    color: #de7e4c; }
  .settings .main-settings .rest,
  .settings .user-settings .rest,
  .settings .comp-settings .rest {
    display: grid;
    grid-template-columns: 2fr 0.1fr 1fr; }
  .settings .main-settings .sep,
  .settings .user-settings .sep,
  .settings .comp-settings .sep {
    width: 100%;
    height: 1px;
    background: #ccc;
    margin: 1rem 0; }
  .settings .main-settings .sep-v,
  .settings .user-settings .sep-v,
  .settings .comp-settings .sep-v {
    height: 100%;
    width: 1px;
    background: #ccc;
    margin: 0 1rem; }
  .settings .main-settings .info,
  .settings .user-settings .info,
  .settings .comp-settings .info {
    margin: 0 2rem;
    text-align: start; }
    .settings .main-settings .info .head,
    .settings .user-settings .info .head,
    .settings .comp-settings .info .head {
      font-weight: bold;
      font-size: 1.3rem; }
  .settings .main-settings .talk,
  .settings .user-settings .talk,
  .settings .comp-settings .talk {
    color: #999; }
  .settings .main-settings .btn-ok,
  .settings .user-settings .btn-ok,
  .settings .comp-settings .btn-ok {
    margin: 0;
    padding: 3px 2rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }

.settings .user-settings,
.settings .comp-settings {
  margin: 0.5rem auto; }

.alert {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #c53736;
  margin: 0 auto; }

.validate {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .validate .indicator {
    padding: 0 0 0 0.5rem; }
    .validate .indicator i {
      font-size: 1.3rem; }
    .validate .indicator .valid {
      color: #6ac045; }
    .validate .indicator .invalid {
      color: #c53736; }

.notifications .head-main {
  color: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 1rem auto; }
  .notifications .head-main i {
    color: #ef7a30; }

.notifications .notif-container {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem;
  padding-bottom: 0.1rem; }

.header {
  display: flex;
  flex-direction: column;
  align-items: center; }

.admin-sign-in {
  padding: 2rem;
  width: 40rem; }
  .admin-sign-in .head-white {
    text-align: center;
    margin: 0; }
    .admin-sign-in .head-white i {
      -webkit-margin-end: 1rem;
              margin-inline-end: 1rem; }
  .admin-sign-in form {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }

.normal-card, .report-card, .block-card {
  background: white;
  justify-self: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333; }
  .normal-card .img, .report-card .img, .block-card .img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center; }
  .normal-card .info, .report-card .info, .block-card .info {
    padding: 1rem 0 0 0;
    text-align: center; }
    .normal-card .info .name, .report-card .info .name, .block-card .info .name {
      font-size: 1.4rem;
      line-height: 1;
      color: #de7e4c; }
      .normal-card .info .name:hover, .report-card .info .name:hover, .block-card .info .name:hover {
        text-decoration: none;
        color: #f4623a; }

.report-card .img {
  width: 80px;
  height: 80px; }

.report-card .reports {
  padding: 3px 10px;
  border-radius: 5px;
  margin: 0;
  margin-top: 8px;
  width: 100%;
  text-align: center;
  background: #c53736;
  color: white; }
  .report-card .reports:hover {
    text-decoration: none;
    background: #a83039; }

.block-card {
  background: #c53736; }
  .block-card .img {
    width: 80px;
    height: 80px; }
  .block-card .info .name {
    color: white; }
    .block-card .info .name:hover {
      color: black; }
  .block-card .info .code {
    color: white; }
  .block-card .blocked {
    color: white;
    font-size: 1.8rem;
    line-height: 1;
    margin: 0;
    margin-top: 10px;
    text-align: center; }
    .block-card .blocked i {
      color: white; }

.contact-card {
  background: white;
  justify-self: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333; }
  .contact-card .remove {
    position: absolute;
    top: 5px;
    right: 10px; }
    .contact-card .remove i {
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .contact-card .remove i:hover {
        color: #ef7a30; }
  .contact-card p {
    margin: 0;
    line-height: 1; }
  .contact-card .name {
    font-size: 1.5rem;
    color: #de7e4c; }
  .contact-card .email {
    color: black;
    margin-bottom: 10px; }
  .contact-card .replied {
    color: black;
    margin-top: 1rem;
    text-align: right; }
    .contact-card .replied i {
      color: #de7e4c; }
  .contact-card .message {
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    height: 150px !important;
    overflow: auto; }
  .contact-card .btn-ok {
    margin: 1rem 0 0 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 1rem auto 0 auto;
    padding: 3px 1rem; }

.feedback-card {
  background: white;
  justify-self: center;
  padding: 1rem;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0.5rem; }
  .feedback-card .img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center; }
  .feedback-card .feed {
    grid-column: 1 / 3;
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    height: 150px !important;
    overflow: auto;
    margin: 0; }
  .feedback-card .rate {
    color: #de7e4c; }
    .feedback-card .rate li {
      margin: 2px; }
  .feedback-card .info {
    padding: 1rem 0 0 0; }
    .feedback-card .info .name {
      font-size: 1.3rem;
      line-height: 1;
      color: #de7e4c; }
      .feedback-card .info .name:hover {
        text-decoration: none;
        color: #f4623a; }
    .feedback-card .info .username {
      color: black;
      margin: 0; }

.repos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin: 1rem; }
  .repos .report {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    padding: 1rem;
    position: relative; }
    .repos .report .img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
      align-self: center;
      justify-self: center; }
    .repos .report .created {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.5rem; }
    .repos .report p {
      margin: 0; }
    .repos .report .info {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-gap: 1rem; }
      .repos .report .info .naming {
        align-self: center; }
        .repos .report .info .naming .name {
          font-size: 1.5rem;
          color: #ef7a30;
          line-height: 1; }
    .repos .report .rep {
      background: #f4f4f4;
      padding: 0.5rem;
      color: #333;
      border-radius: 5px;
      height: 130px !important;
      overflow: auto; }

.account-info {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-gap: 1rem; }
  .account-info .info {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .account-info .info .name {
      font-weight: bold;
      font-size: 1.2rem; }
    .account-info .info .head {
      color: #f4623a;
      font-size: 1.5rem;
      font-weight: bold; }
  .account-info .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .account-info .actions .roles {
      padding: 1rem 0; }
      .account-info .actions .roles .role {
        display: inline-block;
        padding: 0 0.5rem; }
        .account-info .actions .roles .role i {
          color: #6ac045;
          -webkit-margin-end: 0.5rem;
                  margin-inline-end: 0.5rem; }
    .account-info .actions .btn-s {
      display: flex;
      flex-direction: row; }
      .account-info .actions .btn-s .btn-ok {
        margin: 0 0.5rem;
        padding: 3px 1rem;
        cursor: pointer; }

.catching {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem;
  margin: 1rem 0; }
  .catching .catches {
    padding: 1rem; }
    .catching .catches .catch {
      display: inline-block;
      padding: 3px 1rem;
      background: #ddd;
      margin: 3px 0.5rem;
      border-radius: 5px; }
  .catching .head {
    color: #f4623a;
    font-size: 1.5rem;
    font-weight: bold;
    display: inline-block; }
  .catching .btn-ok {
    margin: 0 1rem;
    display: inline-block;
    padding: 0 2rem;
    cursor: pointer; }

.admins {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem; }
  .admins .edit-admin {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 1.3rem;
    margin: 0 1rem;
    display: inline-block;
    cursor: pointer; }
    .admins .edit-admin:hover {
      color: #de7e4c; }
  .admins .del:hover {
    color: #c53736; }

.check-boxs .head {
  font-size: 1.2rem;
  color: #de7e4c;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem auto; }

.check-boxs .ant-checkbox-wrapper span {
  color: black; }

.profile-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem; }
  .profile-header .img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center; }
  .profile-header .info {
    padding: 1rem 0 0 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .profile-header .info .name {
      font-size: 1.6rem;
      line-height: 1;
      color: #de7e4c;
      margin-bottom: 0.5rem; }
    .profile-header .info .username {
      color: black;
      margin: 0; }
  .profile-header .reports {
    background: white;
    padding: 1rem;
    text-align: center;
    color: #ef7a30;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    width: 150px;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .profile-header .reports .reps {
      color: black;
      font-size: 1rem; }
    .profile-header .reports i {
      font-size: 4rem;
      margin-bottom: 10px; }
    .profile-header .reports p {
      font-size: 1.5rem;
      margin: 0; }
  .profile-header .btn-ok,
  .profile-header .btn-gr {
    display: inline-block;
    margin: 0.5rem;
    padding: 3px 2rem; }

.event-profile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem; }
  .event-profile .imgs {
    width: 100%;
    box-shadow: 0px 0px 15px -7px #333;
    border-radius: 10px;
    position: relative; }
    .event-profile .imgs .city {
      position: absolute;
      top: 1.5rem;
      font-size: 1.3rem;
      color: white;
      background: rgba(0, 0, 0, 0.7);
      padding: 5px 2rem;
      border-radius: 0 10px 10px 0; }
      .event-profile .imgs .city i {
        color: #ef7a30;
        font-size: 1.5rem; }
  .event-profile .data {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.75rem; }
    .event-profile .data p {
      margin: 0;
      font-size: 1.1rem; }
    .event-profile .data .item {
      justify-self: center;
      align-self: center; }
      .event-profile .data .item .bold,
      .event-profile .data .item i {
        font-size: 1.3rem;
        color: #ef7a30; }
      .event-profile .data .item .det {
        text-align: center; }
      .event-profile .data .item .grey {
        background: #eee;
        padding: 0 0.5rem;
        margin: 0 0.5rem;
        border-radius: 5px; }
      .event-profile .data .item .keyword {
        display: inline-block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background: -webkit-gradient(linear, left top, right top, from(#ef7a30), to(#c53736));
        background: linear-gradient(to right, #ef7a30, #c53736);
        padding: 3px 0.5rem;
        color: white;
        margin: 0 0.5rem;
        border-radius: 5px; }
    .event-profile .data .allcol {
      grid-column: 1 / 3; }
      .event-profile .data .allcol .det {
        display: block; }
  .event-profile .meters {
    justify-self: center;
    display: grid;
    grid-template-areas: "name time time" "stat stat del";
    grid-gap: 0.5rem; }
    .event-profile .meters p {
      margin: 0 0.5rem; }
    .event-profile .meters .name {
      grid-area: name;
      font-size: 1.5rem;
      color: #ef7a30;
      align-self: center; }
    .event-profile .meters .timing {
      display: flex;
      flex-direction: row;
      justify-self: center;
      align-self: center;
      grid-area: time; }
      .event-profile .meters .timing i {
        color: #ef7a30; }
    .event-profile .meters .status {
      display: flex;
      flex-direction: row;
      background: #333;
      padding: 0.5rem;
      width: 100%;
      grid-area: stat;
      border-radius: 5px;
      justify-content: center;
      align-items: center; }
      .event-profile .meters .status i {
        color: #ef7a30; }
      .event-profile .meters .status p {
        color: white; }
    .event-profile .meters .btn-del {
      margin: 0;
      grid-area: del; }
  .event-profile .add-info {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem; }
    .event-profile .add-info .host {
      background: white;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -7px #333;
      padding: 1rem;
      display: flex; }
      .event-profile .add-info .host .img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        box-shadow: 0px 0px 15px -7px #333;
        align-self: center;
        justify-self: center; }
      .event-profile .add-info .host .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 1rem; }
        .event-profile .add-info .host .info .name {
          color: #ef7a30;
          font-size: 1.5rem; }
          .event-profile .add-info .host .info .name:hover {
            text-decoration: none;
            color: #de7e4c; }
        .event-profile .add-info .host .info .username {
          line-height: 1; }
        .event-profile .add-info .host .info p {
          margin: 0; }
    .event-profile .add-info .rep-count {
      background: white;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -7px #333;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .event-profile .add-info .rep-count p {
        margin: 0; }
      .event-profile .add-info .rep-count .by {
        font-size: 1.2rem;
        color: #ef7a30; }
  .event-profile .rep-head {
    margin: 1rem 0 0 0;
    color: #ef7a30;
    font-size: 1.5rem;
    line-height: 1; }
  .event-profile .repos {
    grid-column: 1 / 3; }

.tab-head {
  text-align: center; }
  .tab-head .name {
    font-weight: bold; }

.issue {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: url("https://res.cloudinary.com/momants/image/upload/v1589975144/Issue_fk61hc.jpg") no-repeat right bottom/cover;
  padding: 40px;
  border-radius: 10px; }
  .issue .info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px; }
    .issue .info .talk {
      text-align: center;
      max-width: 70%; }

.issue-head {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0;
  font-size: 2.7rem; }

.issue-body {
  font-size: 1.6rem;
  color: white;
  max-width: 800px; }

.btn-issue {
  padding: 10px 30px;
  font-size: 1.3rem;
  background: #ef7a30;
  color: white;
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 20px 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin: 1rem 0; }
  .btn-issue:focus {
    outline: none; }
  .btn-issue i {
    color: white;
    padding: 0 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .btn-issue:hover {
    background: #f4623a;
    text-decoration: none;
    color: black;
    border-radius: 0; }
    .btn-issue:hover i {
      color: black; }

.issue-action {
  color: white;
  margin: 1rem 0;
  font-size: 1.2rem;
  text-align: start; }
  .issue-action .head {
    font-size: 1.5rem;
    font-weight: bold;
    color: white; }

