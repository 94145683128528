@import "../../../layout/theme.scss";

.profile-header {
  @include grid-repeat(3, 1fr, 1rem);
  .img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center;
  }
  .info {
    padding: 1rem 0 0 0;
    text-align: center;
    @include flex-view(column);
    @include center;
    .name {
      font-size: 1.6rem;
      line-height: 1;
      color: $second-color;
      margin-bottom: 0.5rem;
    }
    .username {
      color: black;
      margin: 0;
    }
  }
  .reports {
    background: white;
    padding: 1rem;
    text-align: center;
    color: $main-color;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    width: 150px;
    align-self: center;
    justify-self: center;
    @include flex-view(column);
    @include center;
    .reps {
      color: black;
      font-size: 1rem;
    }
    i {
      font-size: 4rem;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.5rem;
      margin: 0;
    }
  }
  .btn-ok,
  .btn-gr {
    display: inline-block;
    margin: 0.5rem;
    padding: 3px 2rem;
  }
}

.event-profile {
  @include grid-repeat(2, 1fr, 1rem);
  .imgs {
    width: 100%;
    box-shadow: 0px 0px 15px -7px #333;
    border-radius: 10px;
    position: relative;
    .city {
      position: absolute;
      top: 1.5rem;
      font-size: 1.3rem;
      color: white;
      background: rgba($color: #000000, $alpha: 0.7);
      padding: 5px 2rem;
      border-radius: 0 10px 10px 0;
      i {
        color: $main-color;
        font-size: 1.5rem;
      }
    }
  }
  .data {
    @include section;
    @include grid-repeat(2, 1fr, 0.75rem);
    p {
      margin: 0;
      font-size: 1.1rem;
    }
    .item {
      justify-self: center;
      align-self: center;
      .bold,
      i {
        font-size: 1.3rem;
        color: $main-color;
      }
      .det {
        text-align: center;
      }
      .grey {
        background: #eee;
        padding: 0 0.5rem;
        margin: 0 0.5rem;
        border-radius: 5px;
      }
      .keyword {
        display: inline-block;
        width: fit-content;
        background: $btn-color-r;
        padding: 3px 0.5rem;
        color: white;
        margin: 0 0.5rem;
        border-radius: 5px;
      }
    }
    .allcol {
      grid-column: 1 / 3;
      .det {
        display: block;
      }
    }
  }
  .meters {
    justify-self: center;
    display: grid;
    grid-template-areas:
      "name time time"
      "stat stat del";
    grid-gap: 0.5rem;
    p {
      margin: 0 0.5rem;
    }
    .name {
      grid-area: name;
      font-size: 1.5rem;
      color: $main-color;
      align-self: center;
    }
    .timing {
      @include flex-view(row);
      justify-self: center;
      align-self: center;
      grid-area: time;
      i {
        color: $main-color;
      }
    }
    .status {
      @include flex-view(row);
      background: #333;
      padding: 0.5rem;
      width: 100%;
      grid-area: stat;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      i {
        color: $main-color;
      }
      p {
        color: white;
      }
    }
    .btn-del {
      margin: 0;
      grid-area: del;
    }
  }
  .add-info {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem;
    .host {
      @include section;
      display: flex;
      .img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        box-shadow: 0px 0px 15px -7px #333;
        align-self: center;
        justify-self: center;
      }
      .info {
        @include flex-view(column);
        justify-content: center;
        margin: 0 1rem;
        .name {
          color: $main-color;
          font-size: 1.5rem;
          &:hover {
            text-decoration: none;
            color: $second-color;
          }
        }
        .username {
          line-height: 1;
        }
        p {
          margin: 0;
        }
      }
    }
    .rep-count {
      @include section;
      @include flex-view(column);
      @include center;
      p {
        margin: 0;
      }
      .by {
        font-size: 1.2rem;
        color: $main-color;
      }
    }
  }
  .rep-head {
    margin: 1rem 0 0 0;
    color: $main-color;
    font-size: 1.5rem;
    line-height: 1;
  }
  .repos {
    grid-column: 1 / 3;
  }
}

.tab-head {
  text-align: center;
  // @include flex-view(column);
  // @include center;
  .name {
    font-weight: bold;
  }
}
