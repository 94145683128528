@import "../../../layout/theme.scss";

.select-icon {
  width: fit-content;
  font-size: 1rem;
  float: left;
  color: $second-color;
  margin: 0.5rem 0;
  padding: 6px 11px 6px 12px;
  border-bottom: 2px $second-color solid;
  border-bottom-left-radius: 5px !important;
}
.ant-input-group {
  width: 100%;
  display: flex !important;
  .ant-select {
    .ant-select-selection {
      border-bottom-right-radius: 5px !important;
    }
  }
}
.ant-select {
  width: 100%;
  .ant-select-selection {
    background: transparent;
    color: $second-color;
    border: none;
    border-bottom: 2px $second-color solid;
    border-radius: 5px;
    font-size: 1rem;
    margin: 0.5rem 0;
    outline: none;
    box-shadow: none !important;
    &:focus,
    &:active {
      outline: none;
    }
    padding: 4px 11px;
    .ant-select-selection__rendered {
      height: 22px;
      margin: 0;
      line-height: 1.5;
    }
    .ant-select-arrow {
      color: $second-color;
    }
  }
}
.ant-select-disabled {
  .ant-select-selection {
    background-color: transparent;
    border-bottom: 2px #ddd solid;
    .ant-select-arrow {
      color: #ddd;
    }
  }
}
.dis-group {
  .select-icon {
    color: #ddd;
    border-bottom: 2px #ddd solid;
  }
}
