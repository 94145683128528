@import "../../../layout/theme.scss";

.notifications {
  .head-main {
    color: black;
    width: fit-content;
    margin: 0 auto 1rem auto;
    i {
      color: $main-color;
    }
  }
  .notif-container {
    @include section;
    padding-bottom: 0.1rem;
  }
}
