@import "../../../layout/theme.scss";

.login-contact {
  @include flex-view(row);
  @include center;
  .contact {
    background: radial-gradient(white, transparent, transparent);
    box-shadow: none;
  }
}
