@import "../../../layout/theme.scss";

.about {
  @include flex-view(column);
  @include center;
  .head-main {
    margin: 0.5rem;
  }
  .about-feed {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-gap: 1rem;
    background: white;
    border-radius: 20px;
    box-shadow: 0px 0px 15px -7px #333;
    .feedback {
      background: $btn-color;
      padding: 1rem;
      border-radius: 20px;
      @include flex-view(column);
      align-items: center;
      .head {
        color: white;
        font-size: 1.3rem;
      }
      form {
        @include flex-view(column);
        align-items: center;
        width: 100%;
        .btn-ok {
          margin: 0.5rem;
          padding: 0 1rem;
          border-radius: 50px;
          background: $btn-color-r !important;
        }
        .ant-input {
          text-align: center;
          border-color: white !important;
          color: white !important;
        }
        .ant-rate {
          .ant-rate-star {
            margin-right: 8px !important;
          }
          .ant-rate-star-full {
            i {
              color: #ffe46c;
            }
          }
        }
      }
    }
    .about {
      padding: 1rem;
      line-height: 2;
      text-align: justify;
    }
  }
  .links {
    display: flex;
    padding: 1rem;
    .link {
      padding: 0 0.5rem;
      cursor: pointer;
    }
  }
  .copy {
    font-size: 0.85rem;
  }
}
