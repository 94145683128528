@import "../../../layout/theme.scss";

.chat-ch {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: 1px $main-color solid;
  border-bottom: 2px $main-color solid;
  border-radius: 5px;
  @include flex-view(row);
  .he {
    font-size: 1.2rem;
    margin: 0;
    color: black;
  }
  justify-content: space-between;
  align-items: center;
}

.event-form {
  display: grid;
  grid-template-columns: 1fr 0.95fr;
  grid-gap: 1rem;
  .data {
    @include section;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .btn-ok {
      width: fit-content;
      margin: auto;
    }
    .info {
      font-size: 0.8rem;
      width: fit-content;
      margin: auto;
    }
    .head {
      color: $main-color;
      font-size: 1.5rem;
      width: fit-content;
      margin-inline-start: 1rem;
      margin: auto;
    }
  }
  .other {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    .images {
      .head {
        color: $main-color;
        font-size: 1.5rem;
        width: fit-content;
        margin-inline-start: 1rem;
        margin: 0 auto 1rem auto;
        line-height: 1;
      }
      @include section;
      padding: 1rem 0.5rem;
      background: #333;
      .clearfix {
        height: 104px;
        width: fit-content;
        .ant-upload-picture-card-wrapper {
          height: 104px;
          display: flex;
        }
        .ant-upload,
        .ant-upload-list-item {
          margin: 0 0.3rem;
        }
      }
      .section {
        display: flex;
      }
      .temp-images {
        width: fit-content;
        display: flex;
        .image {
          width: 102.4px;
          height: 102.4px;
          border-radius: 3px;
          margin: 0 0.3rem;
          display: inline-block;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          background: black;
          cursor: pointer;
          @include trans;
          &:hover {
            opacity: 0.8;
            i {
              color: white;
              opacity: 1;
            }
          }
          i {
            position: absolute;
            font-size: 1.5rem;
            color: white;
            opacity: 0;
            @include trans;
          }
        }
      }
    }
    .keywords {
      @include section;
    }
    .map {
      height: 270px;
    }
  }
}
