@import "../../../layout/theme.scss";

.account-info {
  @include section;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-gap: 1rem;
  .info {
    @include flex-view(column);
    justify-content: center;
    .name {
      font-weight: bold;
      font-size: 1.2rem;
    }
    .head {
      color: $third-color;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
  .actions {
    @include flex-view(column);
    @include center;
    .roles {
      padding: 1rem 0;
      .role {
        display: inline-block;
        padding: 0 0.5rem;
        i {
          color: $green;
          margin-inline-end: 0.5rem;
        }
      }
    }
    .btn-s {
      @include flex-view(row);
      .btn-ok {
        margin: 0 0.5rem;
        padding: 3px 1rem;
        cursor: pointer;
      }
    }
  }
}

.catching {
  @include section;
  margin: 1rem 0;
  .catches {
    padding: 1rem;
    .catch {
      display: inline-block;
      padding: 3px 1rem;
      background: #ddd;
      margin: 3px 0.5rem;
      border-radius: 5px;
    }
  }
  .head {
    color: $third-color;
    font-size: 1.5rem;
    font-weight: bold;
    display: inline-block;
  }
  .btn-ok {
    margin: 0 1rem;
    display: inline-block;
    padding: 0 2rem;
    cursor: pointer;
  }
}
.admins {
  @include section;
  .edit-admin {
    @include trans;
    font-size: 1.3rem;
    margin: 0 1rem;
    display: inline-block;
    cursor: pointer;
    &:hover {
      color: $second-color;
    }
  }
  .del {
    &:hover {
      color: $alert-color;
    }
  }
}
.check-boxs {
  .head {
    font-size: 1.2rem;
    color: $second-color;
    width: fit-content;
    margin: 1rem auto;
  }
  .ant-checkbox-wrapper {
    span {
      color: black;
    }
  }
}
