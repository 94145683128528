@import "../../../layout/theme.scss";

.reports {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    .head {
      font-size: 1.5rem;
      color: $second-color;
    }
  }
  .line-f {
    margin: 0 0 1rem 0;
  }
}

.report-card {
  @include section;
  @include flex-view(column);
  @include center;
  position: relative;
  .remove {
    position: absolute;
    top: 5px;
    right: 10px;
    i {
      cursor: pointer;
      @include trans;
      &:hover {
        color: $main-color;
      }
    }
  }
  .name {
    font-size: 1.5rem;
    line-height: 1;
    color: $second-color;
    margin-bottom: 0.5rem;
    &:hover {
      text-decoration: none;
      color: $third-color;
    }
  }
  .rep {
    grid-column: 1 / 3;
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    height: 150px !important;
    overflow: auto;
    margin: 0;
    margin-top: 1rem;
    width: 100%;
  }
}
