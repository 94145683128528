@import "./layout/theme.scss";

//------------------------------------- main elements
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #efefef !important;
}
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
  z-index: -10;
  background: url("https://res.cloudinary.com/momants/image/upload/v1596464471/BG-2_qunjue.jpg")
    no-repeat center center/cover;
}
* {
  font-family: "Cairo", sans-serif;
}
a[disabled] {
  background: #ccc;
}
p {
  text-align: start;
}
.container {
  max-width: 1140px !important;
  padding: 1rem;
}
.content {
  margin-inline-start: 200px;
  padding: 4rem 1.5rem;
}
.main-app {
  overflow-x: hidden;
}
.showcase {
  width: 100vw;
  height: 100vh;
  background: url("https://res.cloudinary.com/momants/image/upload/v1588176274/showcase_r2bu3c.jpg")
    no-repeat center center/cover;
  .faded {
    @include fade(0.5);
  }
}
.showcase-admin {
  @extend .showcase;
  background: url("https://res.cloudinary.com/momants/image/upload/v1588176181/admin_k0nohj.jpg")
    no-repeat center center/cover;
}
.lang {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 3;
  i {
    display: inline-block;
    color: $main-color;
    margin: 0;
    padding-left: 6px;
  }
  button {
    background: none;
    border: none;
    color: black;
    outline: none;
    padding: 0;
    padding-left: 6px;
    transition: all $anime-speed ease-in-out;
    &:hover {
      color: $alert-color;
    }
  }
}
.lang-top {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 3;
  i {
    display: inline-block;
    color: $main-color;
    margin: 0;
    padding-left: 6px;
  }
  button {
    background: none;
    border: none;
    color: black;
    outline: none;
    padding: 0;
    padding-left: 6px;
    transition: all $anime-speed ease-in-out;
    &:hover {
      color: $alert-color;
    }
  }
}
.lang-normal {
  i {
    display: inline-block;
    color: $main-color;
    margin: 0;
    padding-left: 6px;
  }
  button {
    background: none;
    border: none;
    color: black;
    outline: none;
    padding: 0;
    padding-left: 6px;
    transition: all $anime-speed ease-in-out;
    &:hover {
      color: $alert-color;
    }
  }
}
/* width */
::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bbb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $second-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $main-color;
}
//------------------------------------- Grid System
.double-grid {
  height: 100%;
  @include grid-repeat(2, 1fr, 1rem);
  @include center();
}
.single-grid {
  height: 100vh;
  @include flex-view(column);
  @include center();
}
.triple-grid {
  @include grid-repeat(3, 1fr, 1rem);
}
.inline-ant {
  @include grid-repeat(2, 1fr, 1.5rem);
  @include center();
  width: 100%;
}
//------------------------------------- search bar
.paginate {
  width: fit-content;
  margin: 1rem auto;
  .ant-pagination {
    .ant-pagination-item-active {
      background: $btn-color;
      a {
        //background: $main-color;
        color: white;
      }
    }
  }
}
.ant-empty {
  padding: 1rem;
  .ant-empty-description {
    width: fit-content;
    margin: auto;
    font-size: 1.5rem;
    color: $alert-color;
  }
}
//------------------------------------- Links
.link-desc {
  margin-bottom: 0;
  font-size: 0.8rem;
}
.link {
  color: $second-color;
  font-size: 0.8rem;
  &:hover {
    text-decoration: none;
    color: $third-color;
  }
}
//------------------------------------- btns
.btn-ok {
  @include btn(white, $btn-color, $btn-color-e);
  display: flex;
  align-items: center;
}
.btn-del {
  @include btn($alert-color, #ccc, #777);
}
.btn-mess {
  @include btn(white, $mess-color, $mess-color-r);
}
.btn-white {
  @include btn(black, white, #eee);
}
.btn-gr {
  @include btn(black, $green, $green-r);
}
.btn-menu {
  @include btn(#444, white, #eee);
  margin: 0;
  padding: 10px 20px 10px 20px;
  width: 100% !important;
  font-size: 1.1rem;
  border-radius: 0;
  text-align: start !important;
  i {
    padding: 0 10px;
  }
  &:hover {
    i {
      padding: 0 12px;
    }
  }
}
.btn-drop {
  color: black;
  text-align: start;
  &:hover {
    text-decoration: none;
    color: $main-color;
  }
  i {
    padding: 0 8px;
  }
}
.btn-bar {
  @include btn(#444, white, $btn-color);
  margin: 0 !important;
  border-radius: 0 !important;
  &:hover {
    color: white;
  }
}
//------------------------------------- headings
.head-white {
  @include head(white);
}
.head-main {
  @include head($main-color);
}
.head-line {
  font-size: 2rem;
  margin-bottom: 0;
  color: $second-color;
}
//------------------------------------- Lines
.line {
  @include line($line-color);
}
.line-f {
  @include line($line-color-f);
}
//------------------------------------- paragraphs
.parag-center-white {
  @include parag(#ccc, 300px, 1rem, center);
}
.info-parag {
  width: fit-content;
  margin: 1rem 0.5rem;
  margin-top: 0;
  color: black;
  font-size: 1.1rem;
}
.home-page {
  overflow-x: hidden;
  //height: 100vh;
}
//------------------------------------- menu
.menu {
  @include flex-view(column);
  @include center();
  z-index: 3;
  position: fixed;
  border-radius: 0 0 35px 35px;
  box-shadow: 0px 0px 15px -7px #333;
  width: 200px;
  height: fit-content;
  background: #fff;
  padding: 20px 0;
  padding-bottom: 0;
  top: 0;
}
.active {
  @include active($main-color, #eee);
}
//------------------------------------- Filter Section
.search-modal {
  background: #eee !important;
  margin: 0 0.5rem !important;
  border-radius: 10px;
  margin-bottom: 1rem !important;
  li {
    a {
      color: black;
      &:hover {
        color: $main-color;
      }
    }
  }
}
.global-dis {
  position: absolute;
  top: 50px;
  //background: rgba($color: #000000, $alpha: 0.3);
  width: 100vw;
  height: 100vh;
}
.search-global {
  background: white !important;
  position: absolute;
  top: 0;
  box-shadow: 0px 0px 25px -9px #333 !important;
  border-radius: 10px;
  .ant-menu-item-group {
    .ant-menu-item-group-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.ant-menu-item-group-title {
  padding-bottom: 0 !important;
  text-align: start;
}
//------------------------------------- Spans
.bold {
  font-weight: bold;
}
//------------------------------------- input form
.error {
  font-size: 0.9rem;
  margin-bottom: 0 !important;
  padding: 0.5rem !important;
  width: fit-content;
}
//------------------------------------- Admin cards
.counter-card {
  @include card($main-color);
}
.alert-card {
  @include card($alert-color);
}
.alert {
  padding: 1rem;
  width: 100%;
  background: linear-gradient(to right, $main-color, indianred) !important;
  border-radius: 5px;
  color: white !important;
  border: none !important;
  box-shadow: 0px 0px 15px -5px black;
}
//------------------------------------- other
.gradient-card {
  @include flex-view(column);
  @include center();
  background: $grad-faded-card;
  padding: 20px;
  border-radius: 10px;
}
.ant-badge-dot {
  top: 2px !important;
  right: 10px !important;
}
//------------------------------------- forms

//------------------------------------- Loader
@keyframes ldio-ybix7bsblui {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-ybix7bsblui div {
  position: absolute;
  animation: ldio-ybix7bsblui 1s linear infinite;
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #ef7a30;
  transform-origin: 50px 52px;
}
.loadingio-spinner-eclipse-25x4osfi4yb {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-ybix7bsblui {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ybix7bsblui div {
  box-sizing: content-box;
}
.loader {
  //height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 4;
}
.tiny-loader {
  margin-inline-end: 10px;
}
/* generated by https://loading.io/ */
@keyframes ldio-ysmpid058t {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-ysmpid058t div {
  box-sizing: border-box !important;
}
.ldio-ysmpid058t > div {
  position: absolute;
  width: 82px;
  height: 82px;
  top: 9px;
  left: 9px;
  border-radius: 50%;
  border: 4px solid #000;
  border-color: #ffffff transparent #ffffff transparent;
  animation: ldio-ysmpid058t 1s linear infinite;
}
.ldio-ysmpid058t > div:nth-child(2) {
  border-color: transparent;
}
.ldio-ysmpid058t > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-ysmpid058t > div:nth-child(2) div:before,
.ldio-ysmpid058t > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  top: -4px;
  left: 35px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 78px 0 0 #ffffff;
}
.ldio-ysmpid058t > div:nth-child(2) div:after {
  left: -4px;
  top: 35px;
  box-shadow: 78px 0 0 0 #ffffff;
}
.loadingio-spinner-dual-ring-c8aqmf23tgd {
  width: 37px;
  height: 37px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-ysmpid058t {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.37);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ysmpid058t div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
@keyframes ldio-m58iv7a50dp {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-m58iv7a50dp div {
  box-sizing: border-box !important;
}
.ldio-m58iv7a50dp > div {
  position: absolute;
  width: 82px;
  height: 82px;
  top: 9px;
  left: 9px;
  border-radius: 50%;
  border: 4px solid #000;
  border-color: #ef7a30 transparent #ef7a30 transparent;
  animation: ldio-m58iv7a50dp 1s linear infinite;
}
.ldio-m58iv7a50dp > div:nth-child(2) {
  border-color: transparent;
}
.ldio-m58iv7a50dp > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-m58iv7a50dp > div:nth-child(2) div:before,
.ldio-m58iv7a50dp > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  top: -4px;
  left: 35px;
  background: #ef7a30;
  border-radius: 50%;
  box-shadow: 0 78px 0 0 #ef7a30;
}
.ldio-m58iv7a50dp > div:nth-child(2) div:after {
  left: -4px;
  top: 35px;
  box-shadow: 78px 0 0 0 #ef7a30;
}
.loadingio-spinner-dual-ring-jj888ggjf9 {
  width: 37px;
  height: 37px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-m58iv7a50dp {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.37);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-m58iv7a50dp div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
//-------------------------------------- Dropdown Ant
.ant-dropdown {
  .drop-colored {
    background: $btn-color;
    li {
      span,
      div {
        color: white;
        @include trans;
        &:hover {
          color: black;
        }
      }
    }
  }
}
.inner-tabs {
  .ant-tabs-bar {
    border: none;
    border-bottom: 1px #bbb solid;
    background: white;
    margin: 0 auto;
    margin-bottom: 10px;
    width: fit-content;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    font-size: 1.1rem;
    width: fit-content;
    text-align: center;
    transition: all $anime-speed ease-in-out;
  }
  .ant-tabs-tab-active {
    background: $btn-color !important;
    color: white !important;
    font-weight: bold !important;
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-content {
    padding-bottom: 10px;
  }
}
.maps {
  width: 200px;
  height: 30px;
  margin: 0.5rem auto 0 auto;
  cursor: pointer;
}
.ant-checkbox-wrapper {
  align-items: unset !important;
  .ant-checkbox {
    margin-top: 0.25rem;
    height: fit-content !important;
  }
}
textarea.ant-input {
  border: 1px $main-color solid !important;
  border-bottom: 2px $main-color solid !important;
}
.slide-container {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  .slider {
    height: 100%;
    .react-slideshow-container {
      height: 100%;
      .nav {
        z-index: 1;
      }
      .react-slideshow-wrapper {
        height: 100%;
        border-radius: 5px;
        .images-wrap {
          height: 100%;
          .each-slide {
            height: 100%;
            div {
              height: 100%;
            }
          }
        }
      }
    }
    .indicators {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin: 0 auto;
    }
  }
}
.grecaptcha-badge {
  bottom: 60px !important;
}
