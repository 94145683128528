@import "../../../layout/theme.scss";

.issue {
  @include flex-view(column);
  width: 80%;
  height: fit-content;
  background: url("https://res.cloudinary.com/momants/image/upload/v1589975144/Issue_fk61hc.jpg")
    no-repeat right bottom/cover;
  padding: 40px;
  border-radius: 10px;
  .info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgba($color: #000000, $alpha: 0.3);
    border-radius: 10px;
    .talk {
      text-align: center;
      max-width: 70%;
    }
  }
}
.issue-head {
  max-width: fit-content;
  margin: 0;
  font-size: 2.7rem;
}
.issue-body {
  font-size: 1.6rem;
  color: white;
  max-width: 800px;
}
.btn-issue {
  @include btn(white, $main-color, $third-color);
  margin: 1rem 0;
}

.issue-action {
  color: white;
  margin: 1rem 0;
  font-size: 1.2rem;
  text-align: start;
  .head {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
  }
}
