@import "../../layout/theme.scss";

.sign-up {
  .ant-tabs-bar {
    width: 400px;
    margin: auto;
    border: none;
    color: white;
    border-radius: 5px;
  }
  .ant-tabs-nav {
    width: 100%;
    .ant-tabs-tab {
      background: $faded-card;
      border-radius: 5px;
      margin: 0;
      width: 50%;
      font-size: 1.1rem;
      text-align: center;
    }
    .ant-tabs-tab-active {
      background: none;
    }
    .ant-tabs-ink-bar {
      width: 50% !important;
      display: none !important;
    }
  }
  width: 400px;
  margin: auto;
  background: $faded-card;
  border-radius: 5px;
  form {
    padding: 20px;
    @include flex-view(column);
    @include center();
    .terms {
      text-align: center;
      display: inline-block;
      font-size: 0.8rem;
    }
    .anticon-calendar {
      color: $second-color;
    }
    .btn-ok {
      margin-bottom: 0;
    }
    .validate {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .indicator {
        padding: 0 0 0 0.5rem;
        i {
          font-size: 1.3rem;
        }
        .valid {
          color: $green;
        }
        .invalid {
          color: $alert-color;
        }
      }
    }
  }
}
