@import "../../layout/theme.scss";

.header {
  @include flex-view(column);
  align-items: center;
}

.admin-sign-in {
  padding: 2rem;
  width: 40rem;
  .head-white {
    text-align: center;
    margin: 0;
    i {
      margin-inline-end: 1rem;
    }
  }
  form {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
