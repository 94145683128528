@import "../../../layout/theme.scss";

.ant-input,
.ant-time-picker-input {
  background: transparent !important;
  color: $second-color !important;
  border: none !important;
  border-bottom: 2px $second-color solid !important;
  border-radius: 5px !important;
  font-size: 1rem !important;
  height: 2rem !important;
  padding: 4px 11px !important;
  margin: 0.5rem 0 !important;
  outline: none !important;
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}
.dis-input {
  .ant-input-disabled {
    background-color: transparent !important;
    border-bottom: 2px #ddd solid;
  }
  i {
    color: #ddd !important;
    &:hover {
      color: #ddd !important;
    }
  }
}
.ant-input-affix-wrapper {
  .ant-input {
    padding: 4px 3rem !important;
  }
  i {
    font-size: 1rem;
    color: $second-color;
    &:hover {
      color: $second-color;
    }
  }
}
.normal-input {
  .ant-input {
    padding: 4px 11px !important;
  }
}
.prefix-input {
  .ant-input {
    padding: 4px 11px 4px 3rem !important;
  }
}
.sufix-input {
  .ant-input {
    padding: 4px 3rem 4px 11px !important;
  }
}
.pass-noshow {
  .ant-input-suffix {
    .ant-input-password-icon {
      display: none;
    }
  }
}
