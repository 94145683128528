@import "../../../layout/theme.scss";

.ant-tag {
  background: $btn-color;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.5rem;
  font-size: 1.1rem;
  color: white;
  margin: 0.2rem;
  cursor: pointer;
  &:hover {
    background: $btn-color-e !important;
    opacity: 1 !important;
  }
  i {
    font-size: 1.1rem;
    padding: 0 0.5rem;
    &:hover {
      color: white !important;
    }
  }
}
