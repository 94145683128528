@import "../../layout/theme.scss";

.sign-in {
  .head-main {
    margin: 0.8rem 0;
  }
  form {
    @include flex-view(column);
    @include center;
    .input-form {
      width: fit-content;
      .input {
        width: 300px;
        transition: all $anime-speed ease-in-out;
        &:focus {
          width: 350px;
        }
      }
    }
  }
}

.intro {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  padding-top: 7rem;
  .upper {
    @include flex-view(column);
    @include center();
    .parag {
      font-size: 1rem;
    }
  }
  .btn-ok {
    color: white;
  }
  .map {
    width: 90%;
    height: 90%;
    border-radius: 0;
  }
}

.read-more {
  padding: 1rem;
  .container {
    @include flex-view(column);
    color: white;
    font-size: 1.3rem;
    .section {
      display: flex;
      @include center;
      .talk {
        .slogan {
          font-size: 2rem;
          text-align: center;
        }
      }
      .about {
        text-align: justify;
        line-height: 2;
        margin-inline-end: 2rem;
      }
    }
  }
}

.service {
  padding: 2rem;
  .head-main {
    width: fit-content;
    margin: auto;
    color: black;
  }
  .section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    padding: 1rem;
    .item {
      @include flex-view(column);
      align-items: center;
      i {
        font-size: 3rem;
        color: $second-color;
      }
      .head {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        margin: 0.5rem 0;
      }
      .talk {
        max-width: 250px;
        text-align: center;
      }
    }
  }
}

.categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .item {
    height: 300px;
    @include flex-view(column);
    @include center;
    position: relative;
    &:hover {
      &::before {
        opacity: 0.8;
      }
      .head,
      .type {
        transform: scale(1.1);
        opacity: 1;
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: $second-color;
      opacity: 0;
      z-index: 1;
      @include trans;
    }
    .head,
    .type {
      color: white;
      font-size: 1.3rem;
      z-index: 2;
      opacity: 0;
      @include trans;
    }
    .head {
      font-weight: bold;
    }
  }
}

.contact-home {
  padding: 1rem;
  grid-gap: 1rem;
  .contact {
    justify-self: center;
  }
}

.home-page {
  .copy {
    background: #333;
    padding: 1rem;
    @include flex-view(column);
    @include center;
    color: white;
    text-align: center;
  }
}
.get-app {
  @include flex-view(column);
  @include center;
  padding: 3rem;
  .label {
    color: white;
    font-size: 2rem;
  }
  .imgs {
    @include flex-view(row);
    @include center;
    .img {
      width: 300px;
      height: 100px;
      margin: 1rem 3rem;
      @include trans;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
      }
    }
  }
}
.top-mom {
  padding: 2rem;
  .head-main {
    width: fit-content;
    margin: auto;
    color: black;
  }
  .slick-arrow::before {
    color: black;
  }
  .slick-slide {
    .event-map-card {
      margin: auto;
    }
  }
}
.white-card {
  @include shadow;
  padding: 2rem;
  border-radius: 10px;
  width: 60%;
  background: rgba($color: #000000, $alpha: 0.6);
  height: 90vh;
  overflow: auto;
}

@media only screen and (max-width: 1024px) {
  .griding {
    grid-template-columns: 1fr;
  }
  .sign-in {
    display: none;
  }
  .read-more {
    .container {
      .section {
        .talk {
          .about {
            text-align: center;
            margin: 0;
          }
        }
        img {
          display: none;
        }
      }
    }
  }
  .categories {
    grid-template-columns: 1fr 1fr;
  }
  .contact-home {
    grid-template-columns: 1fr;
    .contact {
      width: 100%;
    }
    .head {
      width: 100%;
      img {
        width: 200px !important;
      }
    }
  }
  .service {
    .section {
      grid-template-columns: 1fr 1fr;
    }
  }
  .get-app {
    .imgs {
      @include flex-view(column);
    }
  }
  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    .map {
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .categories {
    grid-template-columns: 1fr;
  }
  .service {
    .section {
      grid-template-columns: 1fr;
    }
  }
}
