@import "../../../layout/theme.scss";

.other {
  .head {
    color: black;
    font-size: 1.5rem;
    width: fit-content;
    margin: 1rem auto;
  }
  .line {
    margin: auto;
  }
  .items {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
}

.pic-section {
  @include section;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  .pic {
    margin-bottom: 1rem;
  }
}
.info-section {
  @include section;
}
.edit-profile {
  direction: ltr;
  .btn-ok {
    grid-column: 1 / 3;
    width: fit-content;
    margin: auto;
  }
}
