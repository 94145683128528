@import "../../../layout/theme.scss";

.chatting {
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  grid-gap: 1rem;
  padding: 1rem 0;
  .chat,
  .chats {
    @include section;
    height: 80vh;
  }
  .chat {
    .chat-head {
      height: 20%;
      position: relative;
      display: grid;
      grid-template-columns: 1.5fr 5fr 0.5fr;
      grid-gap: 1rem;
      .delete {
        color: #bbb;
        cursor: pointer;
        @include trans;
        justify-self: center;
        height: fit-content;
        &:hover {
          color: $alert-color;
        }
      }
      .img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        box-shadow: 0px 0px 15px -7px #333;
        align-self: center;
        justify-self: center;
      }
      i {
        font-size: 1.5rem;
      }
      .info {
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .name {
          color: $main-color;
          font-size: 1.5rem;
          width: fit-content;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .line {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0;
        width: 100%;
      }
    }
    .chat-body {
      height: 70%;
      background: #eee;
      padding: 1rem 0 1rem 1rem;
      border-radius: 15px;
      overflow: auto;
      direction: ltr;
      .load {
        font-size: 1.2rem;
        color: black;
        border: none;
        outline: none;
        width: fit-content;
        margin: auto;
        background: transparent;
        cursor: pointer;
        @include trans;
        &:hover {
          color: $main-color;
        }
      }
      .own-mess {
        background: $second-color;
        color: white;
        margin: 0.5rem 1rem 0 auto;
        word-wrap: break-word;
      }
      .own-time {
        width: fit-content;
        font-size: 0.8rem;
        margin: 0 0 0 auto;
        color: #ddd;
      }
      .other-time {
        width: fit-content;
        font-size: 0.8rem;
        color: #999;
      }
      .other-mess {
        margin: 0.5rem;
        margin-bottom: 0;
        margin-left: 0;
        background: white;
        word-wrap: break-word;
      }
      .own-mess,
      .other-mess {
        width: fit-content;
        padding: 3px 1rem;
        border-radius: 10px;
        max-width: 350px;
      }
    }
    .chat-text {
      form {
        height: 10%;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        .send {
          font-size: 1.3rem;
          padding: 1rem;
          color: #aaa;
          background: transparent;
          border: none;
          cursor: pointer;
          outline: none;
          @include trans;
          &:hover {
            color: $main-color;
          }
        }
      }
    }
    .chat-now {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      .logo {
        width: 200px;
      }
      .chat-lbl {
        font-size: 1.5rem;
        color: #bbb;
      }
    }
  }
  .chats {
    padding: 0;
    overflow: auto;
    .ant-input {
      background: #fff !important;
      border: none !important;
      border: 1px $main-color solid !important;
      border-radius: 50px !important;
    }
  }
}

.chat-item {
  text-decoration: none;
  width: 100%;
  display: grid !important;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1rem;
  @include trans;
  padding: 1rem;
  padding-bottom: 0;
  border-radius: 5px;
  margin: 0 !important;
  direction: ltr;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    background: #eee;
  }
  .img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
  }
  .info {
    width: 100%;
    .name {
      color: $main-color;
      font-size: 1.1rem;
    }
    .activity {
      display: grid;
      grid-template-columns: 2fr 0.5fr;
      .latest {
        color: #bbb;
        max-width: 202px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .seen {
        color: white;
        background: $main-color;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        justify-self: center;
        border-radius: 50%;
      }
    }
    .line {
      margin: 0;
      margin-top: 1rem;
      width: 100%;
      height: 1px;
      background: #ddd;
    }
  }
}
.chat-item-active {
  @extend .chat-item;
  background: #eee;
  &:hover {
    background: #eee;
  }
}
.search-top {
  padding: 0 1rem;
}
