$main-color: #ef7a30;
$second-color: #de7e4c;
$third-color: #f4623a;
$alert-color: #c53736;
$alert-color-d: #a83039;
$green: #6ac045;
$green-r: #39a914;
$nav-color: linear-gradient(to right, #d44838, #a83039, #2d1a3a);
$logo-color: linear-gradient(to right, #bf1e2e, #ffe46c);
$logo-color-r: linear-gradient(to right, #ffe46c, #bf1e2e);
$mess-color: linear-gradient(to right, #0088d2, #23aaf2);
$mess-color-r: linear-gradient(to right, #23aaf2, #0088d2);
$btn-color: linear-gradient(to right, #c53736, #ef7a30);
$btn-color-e: linear-gradient(to right, #c53736, #ef7a30, #ef7a30);
$btn-color-r: linear-gradient(to right, #ef7a30, #c53736);
$line-color: linear-gradient(to right, transparent, #ef7a30, transparent);
$line-color-f: linear-gradient(to right, #ef7a30, transparent);
$grad-faded-card: linear-gradient(
  rgba(0, 0, 0, 0),
  rgba(0, 0, 0, 0.65),
  rgba(0, 0, 0, 0.9)
);
$faded-card: rgba(0, 0, 0, 0.7);

$anime-speed: 0.3s;

@mixin fade($opacity) {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, $opacity);
}
@mixin trans {
  transition: all $anime-speed ease-in-out;
}
@mixin btn($color, $back-color, $hover-color) {
  padding: 10px 30px;
  font-size: 1.3rem;
  background: $back-color;
  color: $color;
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 20px 10px;
  transition: all $anime-speed;
  width: fit-content;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  i {
    color: $color;
    padding: 0 5px;
    transition: all $anime-speed;
  }
  &:hover {
    background: $hover-color;
    text-decoration: none;
    color: black;
    border-radius: 0;
    i {
      color: black;
    }
  }
}
@mixin active($color, $back-color) {
  color: $color;
  background: $back-color;
  i {
    color: $color;
  }
}
@mixin section {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  padding: 1rem;
}
@mixin line($back-color) {
  width: 80%;
  height: 2.5px;
  background: $back-color;
  margin: 25px auto;
}
@mixin center {
  justify-content: center;
  align-items: center;
}
@mixin grid-repeat($columns, $fractions, $gap) {
  display: grid;
  grid-template-columns: repeat($columns, $fractions);
  grid-gap: $gap;
}
@mixin flex-view($direction) {
  display: flex;
  flex-direction: $direction;
}
@mixin parag($color, $max-width, $size, $align) {
  color: $color;
  max-width: $max-width;
  font-size: $size;
  text-align: $align;
}
@mixin head($color) {
  color: $color;
  font-size: 1.5rem;
  margin: 1rem 0;
  width: fit-content;
  //text-shadow: 0.5px 0.5px 10px #333;
}
@mixin card($color) {
  background: white;
  display: inline-block;
  margin: auto;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 15px -7px $color;
  width: 250px;
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  i {
    font-size: 3rem;
    align-self: center;
    color: $color;
  }
  .info {
    align-self: center;
    text-align: right;
    p {
      margin: 0;
      color: black;
    }
    .count {
      font-size: 2rem;
      color: $color;
    }
  }
  transition: all $anime-speed ease-in-out;
  &:hover {
    i {
      color: white;
    }
    .info {
      p {
        color: white;
      }
    }
    background: $color;
  }
}
@mixin shadow {
  box-shadow: 0px 0px 15px -7px #333;
}
