@import "../../../layout/theme.scss";

.reviews {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    .head {
      font-size: 1.5rem;
      color: $second-color;
    }
    .count {
      margin-inline-start: 1rem;
    }
  }
  .line-f {
    margin: 0 0 1rem 0;
  }
}
.user-review-card {
  @include section;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .remove {
    position: absolute;
    top: 5px;
    right: 10px;
    i {
      cursor: pointer;
      @include trans;
      &:hover {
        color: $main-color;
      }
    }
  }
  .feed {
    grid-column: 1 / 3;
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    height: 150px !important;
    overflow: auto;
    margin: 0;
    width: 100%;
  }
  .by {
    margin: 0;
    font-size: 0.9rem;
  }
  .name {
    font-size: 1.5rem;
    line-height: 1;
    color: $second-color;
    margin-bottom: 0.5rem;
    &:hover {
      text-decoration: none;
      color: $third-color;
    }
  }
  .info {
    padding: 0 0 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .host-name {
      font-size: 1.3rem;
      line-height: 1;
      color: $second-color;
      &:hover {
        text-decoration: none;
        color: $third-color;
      }
    }
    .username {
      color: black;
      margin: 0;
    }
    .rate {
      color: $second-color;
      li {
        margin: 2px;
      }
    }
  }
}
