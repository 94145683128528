@import "../../../layout/theme.scss";

.normal-card {
  background: white;
  justify-self: center;
  padding: 1rem;
  @include flex-view(column);
  width: 250px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  .img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center;
  }
  .info {
    padding: 1rem 0 0 0;
    text-align: center;
    .name {
      font-size: 1.4rem;
      line-height: 1;
      color: $second-color;
      &:hover {
        text-decoration: none;
        color: $third-color;
      }
    }
  }
}
.report-card {
  @extend .normal-card;
  .img {
    width: 80px;
    height: 80px;
  }
  .reports {
    padding: 3px 10px;
    border-radius: 5px;
    margin: 0;
    margin-top: 8px;
    width: 100%;
    text-align: center;
    background: $alert-color;
    color: white;
    &:hover {
      text-decoration: none;
      background: $alert-color-d;
    }
  }
}
.block-card {
  @extend .normal-card;
  background: $alert-color;
  .img {
    width: 80px;
    height: 80px;
  }
  .info {
    .name {
      color: white;
      &:hover {
        color: black;
      }
    }
    .code {
      color: white;
    }
  }
  .blocked {
    color: white;
    font-size: 1.8rem;
    line-height: 1;
    margin: 0;
    margin-top: 10px;
    text-align: center;
    i {
      color: white;
    }
  }
}

.contact-card {
  background: white;
  justify-self: center;
  padding: 1rem;
  @include flex-view(column);
  width: 250px;
  height: fit-content;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  .remove {
    position: absolute;
    top: 5px;
    right: 10px;
    i {
      cursor: pointer;
      @include trans;
      &:hover {
        color: $main-color;
      }
    }
  }
  p {
    margin: 0;
    line-height: 1;
  }
  .name {
    font-size: 1.5rem;
    color: $second-color;
  }
  .email {
    color: black;
    margin-bottom: 10px;
  }
  .replied {
    color: black;
    margin-top: 1rem;
    text-align: right;
    i {
      color: $second-color;
    }
  }
  .message {
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    height: 150px !important;
    overflow: auto;
  }
  .btn-ok {
    margin: 1rem 0 0 0;
    width: fit-content;
    margin: 1rem auto 0 auto;
    padding: 3px 1rem;
  }
}

.feedback-card {
  background: white;
  justify-self: center;
  padding: 1rem;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0.5rem;
  .img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center;
  }
  .feed {
    grid-column: 1 / 3;
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    height: 150px !important;
    overflow: auto;
    margin: 0;
  }
  .rate {
    color: $second-color;
    li {
      margin: 2px;
    }
  }
  .info {
    padding: 1rem 0 0 0;
    .name {
      font-size: 1.3rem;
      line-height: 1;
      color: $second-color;
      &:hover {
        text-decoration: none;
        color: $third-color;
      }
    }
    .username {
      color: black;
      margin: 0;
    }
  }
}

.repos {
  @include grid-repeat(3, 1fr, 1rem);
  margin: 1rem;
  .report {
    @include section;
    position: relative;
    .img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
      align-self: center;
      justify-self: center;
    }
    .created {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.5rem;
    }
    p {
      margin: 0;
    }
    .info {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-gap: 1rem;
      .naming {
        align-self: center;
        .name {
          font-size: 1.5rem;
          color: $main-color;
          line-height: 1;
        }
      }
    }
    .rep {
      background: #f4f4f4;
      padding: 0.5rem;
      color: #333;
      border-radius: 5px;
      height: 130px !important;
      overflow: auto;
    }
  }
}
