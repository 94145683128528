@import "../../layout/theme.scss";

.terModal {
  .head {
    font-weight: bolder;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .subHead {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  .talk {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

.recenter {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  margin: 10px;
  margin-right: 60px;
  background: white;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  color: #666;
  font-size: 1.3rem;
  outline: none;
  @include trans;
  &:hover {
    color: $second-color;
  }
}
.remarker {
  @extend .recenter;
  margin-right: 110px;
}
.map-search {
  position: absolute;
  top: 0.4rem;
  left: 0.3rem;
  width: 55%;
  height: 50px;
  padding-left: 16px;
  margin-top: 2px;
  margin-bottom: 100px;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.navbar {
  background: $nav-color;
  position: fixed !important;
  width: 100vw;
  height: 55px;
  margin-bottom: 0;
  box-shadow: 0px 0px 15px -7px #333;
  z-index: 2;
  .container {
    padding: 0;
    display: grid !important;
    grid-template-columns: 2fr 1fr;
    .controls {
      margin-inline-start: 205px;
      padding: 0 1.5rem;
      display: flex;
      align-items: center;
      .ant-input {
        background: #fff !important;
        width: 240px !important;
        border: none !important;
        border: 1px $main-color solid !important;
        border-radius: 50px !important;
        margin: 0 !important;
      }
      .ant-input-affix-wrapper {
        width: fit-content !important;
      }
      .btn-ok {
        margin: 0 1rem;
        padding: 0 1rem;
        border-radius: 50px;
      }
    }
    .modals {
      display: flex;
      align-items: center;
      justify-self: end;
      color: white;
      .name {
        margin: 0 0.5rem;
        font-size: 1.3rem;
        padding: 0 1rem;
        color: white;
        @include trans;
        text-decoration: none;
        &:hover {
          text-decoration: none;
          background: #a83039;
          border-radius: 5px;
          cursor: pointer;
        }
      }
      i {
        margin: 0 0.5rem;
        font-size: 1.2rem;
        @include trans;
        &:hover {
          color: $main-color;
          cursor: pointer;
        }
      }
      .img {
        width: 35px;
        height: 35px;
        margin: 0 0.5rem;
        border-radius: 50%;
        box-shadow: 0px 0px 15px -7px #333;
        align-self: center;
        justify-self: center;
      }
    }
  }
}

.label-input {
  width: 100%;
  p {
    font-size: 1.3rem;
    color: white;
    margin: 0.5rem 0;
    max-width: fit-content;
  }
  input {
    display: block;
    background: none;
    border: 1px white solid;
    width: 60%;
    outline: none;
    padding: 0.3rem 1rem;
    color: white;
  }
}

.input-form {
  width: 100%;
  display: flex;
  .input {
    background: transparent;
    border: none;
    border-bottom: 2px $second-color solid;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    color: $second-color;
    font-size: 1rem;
    outline: none;
  }
  label {
    color: white;
    font-size: 1.4rem;
    display: block;
  }
  .label-input {
    border: 1px white solid;
    background: transparent;
    padding: 8px;
    width: 60%;
    font-size: 1.1rem;
    color: white;
    outline: none;
  }
  i {
    //position: absolute;
    padding: 8px;
    font-size: 1.2rem;
    color: $second-color;
  }
}

.tag {
  background: $main-color;
}

.user-card {
  @include section;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 3fr;
  .img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center;
  }
  .info {
    @include flex-view(column);
    justify-content: center;
    margin: 0 1rem;
    justify-self: self-start;
    text-align: start;
    .name {
      color: $main-color;
      font-size: 1.3rem;
      width: 147px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        text-decoration: none;
        color: $second-color;
      }
    }
    .username {
      line-height: 1;
    }
    p {
      margin: 0;
    }
    .actions {
      margin: 0;
      margin-top: 10px;
      i {
        background: #ddd;
        padding: 5px;
        font-size: 1rem;
        width: 30px;
        height: 30px;
        text-align: center;
        margin-right: 10px;
        border-radius: 50%;
        cursor: pointer;
        transition: all $anime-speed ease-in-out;
        &::before {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        &:hover {
          color: white;
          background: $main-color;
        }
      }
      .block {
        &:hover {
          background: $alert-color;
        }
      }
      .follow {
        &:hover {
          background: #0088d2;
        }
      }
    }
  }
}

.request-card {
  @extend .user-card;
  .decision {
    display: flex;
    margin-top: 10px;
    button {
      margin: 0;
      padding: 1px 10px;
      margin-right: 5px;
      font-size: 1.1rem;
    }
  }
}

.blocked-card {
  @extend .user-card;
  background: $alert-color;
  .info {
    .name {
      color: white;
      &:hover {
        text-decoration: none;
        color: white;
      }
    }
    button {
      margin: 0;
      padding: 1px 10px;
      margin-right: 5px;
      font-size: 1.1rem;
      text-align: center;
      width: 90%;
    }
  }
}

.eventcard {
  background: white;
  justify-self: center;
  padding: 1rem;
  width: 250px;
  height: 200px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 15px -7px #333;
  @include grid-repeat(1, 1fr, 0.5rem);
  .finished {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1px 1rem;
    background: $alert-color;
    color: white;
    border-radius: 0 10px 0 10px;
  }
  .running {
    @extend .finished;
    background: $green-r;
  }
  .archived {
    @extend .finished;
    background: #aaa;
    color: black;
  }
  .catched,
  .reported {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1px 1rem;
    background: $alert-color;
    color: white;
    border-radius: 10px 0 10px 0;
  }
  .catched {
    background: $alert-color-d;
  }
  &:hover {
    text-decoration: none;
  }
  p {
    margin: 0;
  }
  .info {
    align-self: center;
    justify-self: center;
    background: rgba(#000000, 0.8);
    width: 100%;
    line-height: 1;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -7px #333;
    @include flex-view(column);
    text-align: center;
    .name {
      font-size: 1.3rem;
      color: $second-color;
      text-align: center;
      line-height: 1.5;
      width: 186px;
      margin: auto;
      margin-bottom: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .by {
      color: white;
      font-size: 0.8rem;
      width: fit-content;
      margin: auto;
      margin-bottom: 5px;
    }
    .host {
      font-size: 1rem;
      color: $second-color;
      padding: 0 !important;
      margin: 0 auto !important;
      margin-bottom: 1rem !important;
      background: transparent !important;
      @include trans;
      &:hover {
        text-decoration: none;
        color: $alert-color;
      }
    }
    .del {
      margin: 0;
      margin: auto;
    }
    &:hover {
      .timing {
        display: grid;
      }
    }
    .timing {
      @include grid-repeat(2, 1fr, 0.5rem);
      display: none;
      transition: all $anime-speed ease-in-out;
      .time,
      .date,
      .share,
      .view {
        justify-self: center;
        color: white;
        margin: 0.3rem 0;
        font-size: 0.9rem;
        i {
          color: $main-color;
          margin: 0 0.3rem;
        }
      }
    }
  }
}

.event-map-card {
  @extend .eventcard;
  .info {
    .timing {
      display: grid;
    }
  }
}

.metrics {
  display: flex;
  justify-content: center;
  align-items: center;
  .sector {
    margin: 0 1rem;
    width: 85px;
    @include flex-view(column);
    @include center;
    .head {
      font-size: 1.3rem;
      font-weight: bold;
      margin: 0;
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
    .meter {
      font-size: 1.2rem;
    }
  }
  .vert-line {
    width: 1.5px;
    height: 100px;
    background: linear-gradient(transparent, #bbb, transparent);
  }
}

.picname {
  width: fit-content;
  justify-self: center;
  align-self: center;
  @include flex-view(column);
  @include center;
  .pic {
    position: relative;
    width: fit-content;
    .img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
    }
    .upload {
      position: absolute;
      bottom: 0;
      right: 0;
      i {
        font-size: 1.5rem;
        background: white;
        padding: 0.5rem;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0px 0px 15px -7px #333;
        transition: all $anime-speed ease-in-out;
        &:hover {
          background: #eee;
          color: $main-color;
        }
      }
    }
    i {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 1.5rem;
      background: white;
      padding: 0.5rem;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
      transition: all $anime-speed ease-in-out;
      &:hover {
        background: #eee;
        color: $main-color;
      }
    }
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    .edit {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      .name {
        font-size: 1.5rem;
        font-weight: bold;
        width: fit-content;
        margin: 0 auto;
        //line-height: 1;
        color: $main-color;
        &:hover {
          text-decoration: none;
          color: $third-color;
        }
      }
      .username {
        font-size: 1.1rem;
        width: fit-content;
        line-height: 1;
        margin: 0 auto;
      }
    }
    i {
      font-size: 1.1rem;
      cursor: pointer;
      line-height: 1;
      margin-left: 10px;
      transition: all $anime-speed ease-in-out;
      &:hover {
        background: #eee;
        color: $main-color;
      }
    }
  }
}

.actions {
  width: fit-content;
  margin: auto;
  display: flex;
  button {
    margin: 0 0.5rem;
    padding: 5px 10px;
  }
}

.profile-head {
  display: grid;
  grid-template-columns: 1fr 2fr;
  .view {
    align-self: center;
  }
}

.personal {
  background: white;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 1rem 0;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  .head {
    font-weight: bold;
    margin: 0 0.5rem;
    color: $main-color;
  }
  .item {
    display: flex;
    margin: 0.3rem 0;
  }
  .item-h {
    .head {
      width: fit-content;
    }
    .info {
      text-align: start;
      margin: 0 0.5rem;
    }
  }
}

.profile-item {
  background: white;
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  display: flex;
  flex-direction: column;
  .indicator {
    display: flex;
    align-items: center;
    .head {
      color: $main-color;
      font-size: 1.3rem;
      margin: 0 0.5rem;
    }
    .mutual {
      background: #ddd;
      margin: 0 1rem;
      padding: 0 1rem;
      border-radius: 5px;
    }
  }
  .show {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 1rem 0;
  }
  .show-e {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 1rem 0;
  }
  .btn-ok {
    padding: 3px 1rem;
    margin: 0.5rem 0;
    margin: auto;
  }
}

.user-tag {
  display: flex;
  flex-direction: column;
  width: 200px;
  justify-self: center;
  .img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center;
  }
  .info {
    @include flex-view(column);
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    .name {
      color: $main-color;
      font-size: 1.5rem;
      text-align: center;
      &:hover {
        text-decoration: none;
        color: $second-color;
      }
    }
    .username {
      line-height: 1;
    }
  }
}
.notif-mute {
  padding: 1rem;
  color: $alert-color;
}
.profile-event {
  .main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    .event-showcase {
      display: flex;
      flex-direction: column;
      .img {
        height: 300px;
        box-shadow: 0px 0px 15px -7px #333;
        border-radius: 5px;
        position: relative;
        .city {
          position: absolute;
          top: 1.5rem;
          left: 0;
          font-size: 1.3rem;
          color: white;
          background: rgba($color: #000000, $alpha: 0.7);
          padding: 5px 2rem;
          i {
            color: $main-color;
            font-size: 1.5rem;
          }
        }
        .rate {
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 1.3rem;
          background: rgba($color: #000000, $alpha: 0.7);
          padding: 5px 1rem;
          margin: 0;
          border-radius: 10px 10px 0 0;
        }
        .views {
          position: absolute;
          bottom: 0;
          left: 0;
          color: $main-color;
          font-size: 1.3rem;
          background: rgba($color: #000000, $alpha: 0.7);
          padding: 5px 1rem;
          margin: 0;
          border-radius: 10px 10px 0 0;
        }
        .status {
          position: absolute;
          top: 0;
          right: 0;
          .state {
            margin: 0.5rem 0;
            padding: 3px 1rem;
            background: $alert-color-d;
            color: white;
          }
        }
        .show {
          position: absolute;
          bottom: 0;
          right: 7rem;
          font-size: 1.3rem;
          color: white;
          background: rgba($color: #000000, $alpha: 0.7);
          padding: 5px 1rem;
          margin: 0;
          border-radius: 10px 10px 0 0;
          cursor: pointer;
          @include trans;
          &:hover {
            color: $main-color;
            padding-bottom: 1.2rem;
          }
        }
      }
      .timing {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        justify-content: center;
        align-items: center;
        .time {
          display: flex;
        }
        p {
          margin: 0 0.5rem;
        }
        i {
          color: $main-color;
        }
        .name {
          grid-area: name;
          font-size: 1.5rem;
          color: $main-color;
          align-self: center;
        }
      }
      .meters {
        background: #333;
        padding: 0.5rem;
        width: 100%;
        border-radius: 0 0 10px 10px;
        .sep {
          width: 90%;
          height: 1px;
          background: linear-gradient(to right, transparent, #ccc, transparent);
          margin: 0.5rem auto;
        }
        .controls {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          color: $main-color;
          .point {
            cursor: pointer;
          }
          .finished {
            padding: 1px 1rem;
            background: $alert-color;
            color: white;
            border-radius: 5px;
            margin: 0 0.5rem;
          }
          .running {
            @extend .finished;
            background: $green-r;
          }
          .btn-gr,
          .btn-del {
            padding: 0.1rem 1rem;
            margin: 0 0.5rem;
          }
          button {
            padding: 0.1rem 1rem;
            margin: 0 0.5rem;
          }
          p {
            margin: 0 0.5rem;
          }
          .sett {
            margin: 0 0.5rem;
          }
          .likes,
          .sett {
            color: white;
            cursor: pointer;
          }
          .wishlist {
            background: #333;
            padding: 5px;
            font-size: 1.3rem;
            width: 39px;
            height: 39px;
            text-align: center;
            //margin-right: 10px;
            border-radius: 50%;
            color: white;
            cursor: pointer;
            transition: all $anime-speed ease-in-out;
            &::before {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            }
          }
          .wishlist-active {
            @extend .wishlist;
            color: $main-color;
          }
        }
      }
      .share {
        width: fit-content;
        margin: 0;
        display: flex;
        color: black;
        padding-top: 0.3rem;
        align-items: center;
        margin: auto;
        .head {
          color: $main-color;
          font-size: 1.3rem;
        }
        .sh-icon {
          margin: 0 0.5rem;
        }
      }
    }
    .data {
      @include section;
      @include grid-repeat(1, 1fr, 0.75rem);
      p {
        margin: 0;
        font-size: 1.1rem;
      }
      .item {
        //justify-self: center;
        align-self: center;
        .hov {
          &:hover {
            color: $main-color !important;
          }
        }
        .bold,
        i {
          font-size: 1.3rem;
          color: $main-color;
        }
        .det {
          text-align: center;
        }
        .grey {
          background: #eee;
          padding: 0 0.5rem;
          margin: 0 0.5rem;
          border-radius: 5px;
        }
        .keyword {
          display: inline-block;
          width: fit-content;
          background: $btn-color-r;
          padding: 3px 0.5rem;
          color: white;
          margin: 0.5rem;
          border-radius: 5px;
        }
      }
      .key-show {
        @include flex-view(column);
        @include center;
      }
      .allcol {
        grid-column: 1 / 3;
        .det {
          display: block;
        }
      }
    }
    .host {
      @include section;
      display: flex;
      width: fit-content;
      margin: 1rem auto;
      align-items: center;
      .ch-btn {
        cursor: pointer;
        @include trans;
        &:hover {
          color: $main-color;
        }
      }
      .img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        box-shadow: 0px 0px 15px -7px #333;
        align-self: center;
        justify-self: center;
      }
      .info {
        @include flex-view(column);
        justify-content: center;
        margin: 0 1rem;
        .name {
          color: $main-color;
          font-size: 1.5rem;
          &:hover {
            text-decoration: none;
            color: $second-color;
          }
        }
        .username {
          line-height: 1;
        }
        p {
          margin: 0;
        }
      }
    }
    .gr-loc {
      grid-column: 1 / 3;
    }
    .location {
      .label {
        color: $main-color;
        font-size: 1.5rem;
        width: fit-content;
        margin: 0.5rem auto 0.5rem auto;
      }
      .mapping {
        height: 400px;
      }
    }
    .network {
      display: flex;
      flex-direction: column;
      @include center;
    }
  }
  .reviews {
    margin-top: 1rem;
    .label {
      color: $main-color;
      font-size: 1.5rem;
      width: fit-content;
      margin: auto;
      margin-bottom: 1rem;
    }
    .line {
      margin: 1rem auto;
    }
    .items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
    }
  }
  .ant-tabs {
    .ant-tabs-bar {
      border: none;
      border-bottom: 1px #bbb solid;
      background: white;
      margin: 0 auto;
      margin-bottom: 20px;
      width: fit-content;
    }
    .ant-tabs-nav .ant-tabs-tab {
      margin: 0;
      font-size: 1.1rem;
      width: fit-content;
      text-align: center;
      padding: 3px 2rem;
      transition: all $anime-speed ease-in-out;
    }
    .ant-tabs-tab-active {
      background: $btn-color !important;
      color: white !important;
      font-weight: bold !important;
    }
    .ant-tabs-ink-bar {
      display: none !important;
    }
    .ant-tabs-content {
      padding-bottom: 10px;
    }
  }
  .reports {
    @include section;
    display: flex;
    width: fit-content;
    margin: 0 auto;
    @include center;
    i {
      color: $main-color;
      padding: 0 1rem;
      font-size: 4rem;
    }
    .info {
      padding: 0 1rem;
      @include flex-view(column);
      @include center;
      width: 200px;
    }
    .rep {
      color: $main-color;
      font-weight: bold;
      font-size: 1.3rem;
    }
    p {
      margin: 0;
    }
  }
}
.friends {
  .head {
    color: $main-color;
    font-size: 1.5rem;
    width: fit-content;
  }
  .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    .info {
      display: flex;
      flex-direction: column;
      background: white;
      justify-self: center;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -7px #333;
      padding: 1rem 0;
      width: 130px;
      margin: 0.5rem 0;
      .img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        box-shadow: 0px 0px 15px -7px #333;
        align-self: center;
        justify-self: center;
      }
      .name {
        color: black;
        font-size: 1.1rem;
        width: 120px;
        text-align: center;
        margin: 10px auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          text-decoration: none;
          color: $second-color;
        }
      }
      .btn-ok,
      .btn-mess {
        margin: 0;
        padding: 3px 10px;
        width: fit-content;
        margin: auto;
      }
    }
  }
}

.review-card {
  background: white;
  justify-self: center;
  padding: 1rem;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0.5rem;
  .img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center;
  }
  .feed {
    grid-column: 1 / 3;
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    max-height: 150px !important;
    overflow: auto;
    margin: 0;
  }
  .info {
    padding: 1rem 0 0 0;
    align-self: center;
    .name {
      font-size: 1.3rem;
      line-height: 1;
      color: $second-color;
      &:hover {
        text-decoration: none;
        color: $third-color;
      }
    }
    .username {
      color: black;
      margin: 0;
    }
    .rate {
      color: $second-color;
      li {
        margin: 2px;
      }
    }
  }
}
.review-wide {
  background: white;
  justify-self: center;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -7px #333;
  position: relative;
  margin-bottom: 1rem;
  .ident {
    display: flex;
    margin-bottom: 1rem;
    .img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
      margin-inline-end: 2rem;
    }
    .info {
      padding: 1rem 0 0 0;
      .name {
        font-size: 1.3rem;
        line-height: 1;
        color: $second-color;
        &:hover {
          text-decoration: none;
          color: $third-color;
        }
      }
      .username {
        color: black;
        margin: 0;
      }
    }
  }
  .feed {
    background: #f4f4f4;
    padding: 0.5rem;
    color: #333;
    border-radius: 5px;
    margin: 0;
  }
  .rate {
    color: $second-color;
    position: absolute;
    top: 1rem;
    right: 1rem;
    li {
      margin: 2px;
    }
  }
  .rate-rev {
    @extend .rate;
    right: unset;
    left: 1rem;
  }
}

.rate {
  color: $second-color;
  li {
    margin: 2px;
  }
}

.div-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-items {
    @include section;
    position: relative;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .head {
      color: $main-color;
      font-size: 1.5rem;
    }
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: black;
      cursor: pointer;
      &:hover {
        color: $main-color;
      }
    }
  }
}

.filter {
  display: none;
}
.filter-active {
  background: white;
  display: block;
  width: 100%;
  margin: 2.5rem auto 0.5rem auto;
  padding: 1.5rem;
  border-radius: 10px;
  grid-column: 1 / 4;
}

.filter-elem {
  display: flex;
  justify-content: center;
  align-items: center;
  .label-select,
  .sorting {
    width: 30%;
    margin: 0 0.5rem;
    .label {
      width: fit-content;
      margin: auto;
    }
  }
}

.search {
  width: fit-content;
  display: flex;
  align-items: center;
  float: right;
  grid-column: 1 / 4;
  .fa {
    align-self: center;
    font-size: 1.5rem;
    color: $second-color;
  }
  .ant-select {
    width: 15rem;
    margin-inline-start: 1rem;
    .ant-select-selection {
      border: none;
      background: white;
      margin: 0 !important;
    }
  }
  .fa-filter {
    cursor: pointer;
    //margin-inline-start: 1rem;
    transition: all $anime-speed ease-in-out;
    &:hover {
      color: $third-color;
    }
  }
  .search-bar {
    padding: 0.3rem 1rem;
    margin-inline-start: 1rem;
    border: none;
    border-radius: 5px;
    width: 20rem;
    height: fit-content;
    outline: none;
  }
}
.contact {
  @include section;
  @include flex-view(column);
  @include center;
  width: 70%;
  margin: auto;
  .head-main {
    margin: 0;
  }
  .email {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    i {
      color: $main-color;
      padding: 0 1rem;
    }
  }
  .ant-input {
    border-radius: 10px !important;
    border-bottom: none !important;
    border: 1px #999 solid !important;
  }
  .ant-input::placeholder {
    color: rgba($color: #000000, $alpha: 0.7);
  }
  .lower {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 0.3fr;
    grid-gap: 1rem;
  }
  .talk {
    max-width: 500px;
    text-align: center;
  }
  .btn-ok {
    margin: 0;
    padding: 0 2rem;
    height: fit-content;
    align-self: center;
    border-radius: 10px !important;
  }
}

.notif-item {
  height: fit-content;
  display: grid !important;
  grid-template-columns: 1fr 4fr;
  border-bottom: 0.5px #ccc solid;
  width: 20rem;
  white-space: unset;
  &:hover {
    text-decoration: none;
    .info {
      .desc {
        color: $third-color !important;
      }
    }
  }
  .img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -7px #333;
    align-self: center;
    justify-self: center;
  }
  .info {
    @include flex-view(column);
    justify-content: center;
    margin: 0 1rem;
    justify-self: self-start;
    text-align: start;
    width: fit-content;
    .name {
      color: black;
      font-weight: bold;
      font-size: 1.2rem;
    }
    .desc {
      height: fit-content;
      white-space: normal;
      @include trans;
    }
  }
}
.notif-full {
  @extend .notif-item;
  width: 100%;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
}
.notif-contain {
  position: relative;
  display: flex;
}
.delete {
  color: black;
  cursor: pointer;
  @include trans;
  height: fit-content;
  &:hover {
    color: $main-color;
  }
}
.read {
  background: #eee;
}

.landing-foot {
  background: #212529;
  overflow: hidden;
  width: 100%;
  z-index: 3;
  position: relative;
  .elements {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .link {
      color: white;
      padding: 1rem;
      font-size: 1.3rem;
      cursor: pointer;
      @include trans;
      &:hover {
        color: $main-color;
        transform: translateY(-5px);
      }
    }
  }
  .line {
    width: 50%;
    margin: 0 auto;
    height: 0.5px;
  }
  .copyr {
    color: white;
    padding: 1rem;
    width: fit-content;
    margin: 0 auto;
  }
  .social {
    //position: absolute;
    //bottom: 2rem;
    //left: 2rem;
    padding-top: 1rem;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    color: white;
    align-items: center;
    i {
      font-size: 2rem;
      padding: 0 1rem;
      color: white;
      cursor: pointer;
      @include trans;
    }
    .face {
      &:hover {
        color: #0573e7;
      }
    }
    .insta {
      &:hover {
        color: #f25445;
      }
    }
    .twitt {
      &:hover {
        color: #32ccfe;
      }
    }
  }
}
.foot-cont {
  width: 100%;
  display: flex;
  justify-content: center;
}
.primary-footer {
  //background: linear-gradient(to bottom, transparent, white, white, white);
  background: white;
  padding: 0 3rem;
  //border-top: 1px #999 solid;
  box-shadow: 0px 0px 15px -7px #333;
  //padding-top: 1rem;
  border-radius: 35px 35px 0 0;
  overflow: hidden;
  width: 80%;
  z-index: 2;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  .elements {
    .img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      align-self: center;
      justify-self: center;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .link {
      color: #666;
      padding: 1rem;
      font-size: 1rem;
      cursor: pointer;
      @include trans;
      &:hover {
        color: $main-color;
        font-weight: bold;
        transform: translateY(-5px);
      }
    }
  }
  .social {
    //position: absolute;
    //bottom: 2rem;
    //left: 2rem;
    width: fit-content;
    margin: 0;
    display: flex;
    color: black;
    align-items: center;
    i {
      font-size: 1.5rem;
      padding: 0 1rem;
      color: #666;
      cursor: pointer;
      @include trans;
      &:hover {
        transform: translateY(-5px);
      }
    }
    .face {
      &:hover {
        color: #0573e7;
      }
    }
    .insta {
      &:hover {
        color: #f25445;
      }
    }
    .twitt {
      &:hover {
        color: #32ccfe;
      }
    }
  }
}

.link-head {
  display: flex;
  align-items: center;
  .btn-ok {
    padding: 0 1rem;
    font-size: 1rem;
  }
}

.land-nav {
  height: fit-content;
  direction: ltr;
  width: 100%;
  position: fixed;
  z-index: 3;
  padding: 0 6rem;
  background: white;
  @include shadow;
  @include trans;
  top: 0;
  display: flex;
  justify-content: space-between;
  .elements {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    i {
      margin: 0 0.5rem;
      font-size: 1.2rem;
      @include trans;
      &:hover {
        color: $main-color;
        cursor: pointer;
      }
    }
    .img {
      width: 35px;
      height: 35px;
      margin: 0 0.5rem;
      border-radius: 50%;
      box-shadow: 0px 0px 15px -7px #333;
      align-self: center;
      justify-self: center;
    }
    .link {
      color: #666;
      padding: 1rem;
      font-size: 1rem;
      cursor: pointer;
      @include trans;
      &:hover {
        color: $main-color;
        font-weight: bold;
        transform: translateY(-5px);
      }
    }
  }
  .brand {
    display: flex;
    align-items: center;
    //padding: 0.5rem 0;
    .img {
      width: 50px;
      height: 45px;
    }
    .name {
      width: 120px;
      height: 40px;
    }
  }
  &:hover {
    opacity: 1;
  }
}
.land-nav-op {
  @extend .land-nav;
  opacity: 0.8;
}
@media only screen and (max-width: 1024px) {
  .land-nav {
    padding: 0.4rem;
    .elements {
      display: none;
    }
  }
  .contact {
    .lower {
      grid-template-columns: 1fr 1fr;
      .btn-ok {
        grid-column: 1 / 3;
      }
    }
  }
}
